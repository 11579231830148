<template>
  <div class="rratingtimelinecomparison">
    <v-row>
      <v-col
        cols="3"
        class="mr-5"
      >
        <div class="d-flex justify-space-between align-center flex-wrap">
          <h3>Star rating</h3>
          <r-select
            class="rratingtimelinecomparison--selector"
            :show-search="false"
            :items="starRatings"
            @change="onSortChange"
          />
        </div>
      </v-col>
      <v-col cols="3">
        <div class="d-flex justify-space-between align-center flex-wrap">
          <h3>In the last</h3>
          <r-select
            class="rratingtimelinecomparison--selector"
            :show-search="false"
            :items="daysFilter"
            @change="onDayFilterChange"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="series.length"
        class="rratingtimelinecomparison--chart-col"
      >
        <highcharts
          ref="chart"
          class="hc"
          :options="chartOptions"
        />
      </v-col>
      <r-no-results-message v-if="!series.length" />
      <v-col
        v-if="seriesForTable.length"
        class="rratingtimelinecomparison--table-col"
      >
        <div
          v-for="item in seriesForTable"
          :key="item._id"
          class="d-flex rratingtimelinecomparison--table-row"
        >
          <div class="rratingtimelinecomparison--product-color">
            <div
              :style="{'background-color': item.color}"
            />
          </div>
          <div class="rratingtimelinecomparison--product">
            <r-data-image
              type="url"
              class="rratingtimelinecomparison--product-img"
              :ids="[item._id]"
              :size="32"
            />
            <span class="rratingtimelinecomparison--product-name text-truncate">
              {{ item.name }}
            </span>
          </div>
          <div class="rratingtimelinecomparison--rating">
            <span class="rratingtimelinecomparison--rating-number">
              {{ item.data[item.data.length - 1][1].toPrecision(2) }}
            </span>
            <img
              src="@/assets/images/star.svg"
              alt="star"
              class="rratingtimelinecomparison--rating-star"
            >
          </div>
          <div class="rratingtimelinecomparison--period">
            <img
              v-if="decrease(item.data)"
              src="@/assets/images/trendingEntities/red-arrow-down.svg"
              class="rratingtimelinecomparison--period-up-down"
              alt="arrow"
            >
            <img
              v-else
              src="@/assets/images/trendingEntities/green-arrow-up.svg"
              class="rratingtimelinecomparison--period-up-down"
              alt="arrow"
            >
            <span class="rratingtimelinecomparison--period-percent">%</span>
            <span class="rratingtimelinecomparison--period-text">Prev. period</span>
            <span class="rratingtimelinecomparison--period-rating">
              {{ item.data[0][1].toPrecision(2) }}
            </span>
            <img
              src="@/assets/images/star.svg"
              class="rratingtimelinecomparison--period-star"
              alt="star"
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import RDataImage from '@/components/library/atoms/RDataImage'
import { COLORS, getColorForNumber } from '@/utils/constants'
import dateStrToDate from '@/utils/dateStrToDate'
import RNoResultsMessage from '@/components/library/atoms/RNoResultsMessage'

// this is for coordinate of right arrow
// which  depends on  selected days interval
const coords = {
  60: 345, 7: 365, 30: 348, 14: 365,
}

export default {
  name: 'RRatingTimeLineComparison',
  components: {
    RDataImage,
    RSelect,
    RNoResultsMessage,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      seriesForTable: [],
      starRatings: [
        { label: 'Highest star rating', value: 'desc', selected: true },
        { label: 'Lowest star rating', value: 'asc', selected: false },
      ],
      daysFilter: [
        { label: '7 days', value: 7, selected: true },
        { label: '14 days', value: 14, selected: false },
        { label: '30 days', value: 30, selected: false },
        { label: '60 days', value: 60, selected: false },
      ],
      selectedDayFilter: 7,
    }
  },
  computed: {
    chartOptions() {
      const vm = this
      return {
        chart: {
          events: {
            load() {
              if (vm.series.length) {
                const ren = this.renderer
                const rightArrow = ['M', 0, 0, 'L', 100, 0, 'L', 95, 5, 'M', 100, 0, 'L', 95, -5]
                const arrowUp = ['M-6', '0 L0', '-6 6', 0]
                ren.path(rightArrow)
                  .attr({
                    'stroke-width': 0.2,
                    stroke: 'silver',
                  }).translate(this.chartWidth - 104, coords[vm.selectedDayFilter])
                  .add()
                ren.path(arrowUp)
                  .attr({
                    'stroke-width': 2,
                    stroke: 'silver',
                  }).translate(54, 13)
                  .add()
              }
            },
          },
        },
        yAxis: {
          lineWidth: 1,
          title: {
            text: 'Star',
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: true,
            formatter() {
              // blue color all labels except the first one
              if (this.isFirst) {
                return vm.$moment.utc(this.value).format('DD MMM')
              }

              return `<span style="fill: #1691CF">${vm.$moment.utc(this.value).format('DD MMM')}</span>`
            },
          },
          plotLines: [
            {
              color: COLORS.RED,
              width: 2,
              value: dateStrToDate(vm.data[0].history?.[0]?.dateFrom),
              dashStyle: 'ShortDot',
            }],
          tickInterval: 24 * 3600 * 1000,
        },
        plotOptions: {
          series: {
            pointStart: dateStrToDate(vm.data[0].history?.[0]?.dateFrom),
            pointInterval: 24 * 3600 * 1000,
          },
        },
        series: this.series,
      }
    },
  },
  watch: {
    data() {
      this.generateValues()
    },
  },
  mounted() {
    this.generateValues()
  },
  methods: {
    loadMarkers() {
      // these two are for marker
      // won't  be visible some workaround to have  (0, x) value marker
      const firstDate = this.data[0].history[0].dateFrom
      const lastDate = this.data[0].history[this.data[0].history.length - 1].dateFrom
      this.series.push(
        {
          data: [{ x: dateStrToDate(firstDate), y: 0 }],
          name: 'test',
          color: COLORS.BLUE,
          marker: {
            enabled: true,
          },
        },
        {
          data: [{ x: dateStrToDate(lastDate), y: 0 }],
          name: 'test',
          color: COLORS.BLUE,
        },
      )
    },
    generateValues() {
      if (!this.dataIsValid()) {
        this.series = []
        this.seriesForTable = []
        return
      }

      this.series = this.data.map((item, index) => ({
        name: item.name,
        _id: item._id,
        data: item.history
          .map(({ averageRating, dateFrom }) => [dateStrToDate(dateFrom), averageRating]),
        marker: { enabled: true },
        color: getColorForNumber(index),
      }))

      this.seriesForTable = [...this.series]

      this.loadMarkers()
    },
    onDayFilterChange({ value }) {
      this.$emit('change:num', { value })
      this.selectedDayFilter = value
    },
    onSortChange({ value }) {
      this.$emit('change:sort', { value })
    },
    decrease(data) {
      return data[0][1] > data[data.length - 1][1]
    },
    dataIsValid() {
      if (!this.data.length) {
        return false
      }

      return this.data.every((item) => item.history.length === this.selectedDayFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.rratingtimelinecomparison--selector {
  width: calc(100% - 100px);
}
.rratingtimelinecomparison--table-row {
  & > div {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: #F8F8F8;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .rratingtimelinecomparison--product-color {
    flex: 0 0 50px;
    max-width: 50px;

    div {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
  }

  .rratingtimelinecomparison--product {
    flex: 0 0 calc(60% - 50px);
    max-width: calc(60% - 50px);
  }
  .rratingtimelinecomparison--rating {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .rratingtimelinecomparison--period {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .rratingtimelinecomparison--product-img {
    margin-right: 8px !important;
  }
  .rratingtimelinecomparison--product-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #3F3F3F;
  }
  .rratingtimelinecomparison--rating-number,
  .rratingtimelinecomparison--period-text,
  .rratingtimelinecomparison--rating-number {
    font-size: 13px;
    color: #3F3F3F;
    margin-right: 4px;
  }

  .rratingtimelinecomparison--period-text {
    color: #000;
  }

  .rratingtimelinecomparison--rating-number {
    font-size: 12px;
  }

  .rratingtimelinecomparison--period-percent {
    font-size: 17px;
    font-weight: 700;
    color: #00A682;
    margin-right: 4px;
    margin-left: 3px;
  }
}

.rratingtimelinecomparison--chart-col {
  flex: 0 0 47%;
  max-width: 47%;
}
.rratingtimelinecomparison--table-col {
  flex: 0 0 53%;
  max-width: 53%;
}
</style>
