<template>
  <div class="rgroupedtabs">
    <div class="d-flex">
      <div class="d-flex rgroupedtabs--container">
        <div
          v-for="(group, index) in items"
          :key="group.group"
          class="d-flex"
          :style="getGroupTabColor(index)"
        >
          <div
            class="v-tab v-tabs d-flex align-center justify-center"
            :class="isTabActive(index)"
            :style="{'background-color': `${group.color} !important` }"
            @click="(e) => onGroupTabChanged(e, index)"
          >
            <span
              class="text-truncate"
              :title="group.group"
            >
              {{ group.group }}
            </span>
            <v-icon
              v-if="showDeleteGroup && showDeleteGroupItem(index)"
              class="rgroupedtabs--delete-icon ml-2"
              title="Remove Group Tab"
              dense
              @click="$emit('on:delete-group', { value: index })"
            >
              close
            </v-icon>
          </div>
          <v-tabs
            v-if="selectedGroupTab === index"
            v-model="tab"
            background-color="transparent"
          >
            <v-tab
              v-for="(it, ind) in group.items"
              :key="it.key"
              :style="getSubTabColor(ind, index)"
              @change="() => onChangeSubTab(ind)"
            >
              <span
                class="text-truncate"
                :title="it.label"
              >
                {{ it.label }}
              </span>
            </v-tab>
          </v-tabs>
          <div
            v-if="items.length-1 > index"
            class="rgroupedtabs--vertical-row"
          />
        </div>
      </div>
      <div
        v-if="hasSeparateTabSlot"
        class="rgroupedtabs--separate--tab"
      >
        <slot name="separateTab" />
      </div>
    </div>
    <slot name="items" />
  </div>
</template>

<script>

export default {
  name: 'RGroupedTabs',
  props: {
    selectedGroupTab: {
      type: Number,
      default: 0,
    },
    selectedSubTab: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
    },
    showDeleteGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: this.selectedSubTab,
      groupItemColors: {},
    }
  },
  computed: {
    hasSeparateTabSlot() {
      return !!this.$slots.separateTab
    },
  },
  watch: {
    selectedSubTab(newValue) {
      this.tab = newValue
    },
  },
  methods: {
    onChangeSubTab(selectedSubTabIndex) {
      this.$emit('change:sub-tab', selectedSubTabIndex)
    },
    async onGroupTabChanged({ target }, selectedGroupIndex) {
      // tab items to work properly
      // unmounting it from dom
      // waiting next tick and
      // again mounting it

      // when clicked on delete icon don't emit change event
      if (target.classList.contains('rgroupedtabs--delete-icon')) {
        return
      }

      this.$emit('change', selectedGroupIndex)
      await this.$nextTick()
      this.$emit('change:sub-tab', 0)
    },
    isTabActive(index) {
      return this.selectedGroupTab === index ? 'v-tab--active' : ''
    },
    getSubTabColor(ind, index) {
      if (this.selectedSubTab === ind) {
        return {
          'border-top': `1px solid ${this.items[index].color} !important`,
          'border-left': `1px solid ${this.items[index].color} !important`,
          'border-right': `1px solid ${this.items[index].color} !important`,
          'border-bottom': '1px outset rgba(63, 63, 63, 0.2) !important',
        }
      }

      return ''
    },
    getGroupTabColor(index) {
      return { 'border-bottom': `1px solid ${this.groupItemColors[index]} !important` }
    },
    showDeleteGroupItem(index) {
      // not showing delete icon for main (first) group
      return index !== 0
    },
  },
}

</script>

<style scoped>
.v-tabs {
  border: none;
}
.v-tab {
  white-space: nowrap!important;
  background-color: white !important;
}
.v-tab--active {
  background: rgba(63, 63, 63, 0.08) !important;
  color: #3F3F3F !important;
}
.rgroupedtabs--vertical-row {
  border: 1px solid rgba(63, 63, 63, 0.2);
  transform: rotate(90deg);
  height: 0;
  min-width: 13px;
  display: flex;
  align-self: center;
  flex-grow: 0;
}
.rgroupedtabs--container {
  overflow-x: auto;
  border-bottom:  1px solid rgba(63, 63, 63, 0.2)
}
</style>
