<template>
  <div class="raccordion">
    <slot
      :activeItem="selectedItemIndex"
      :onChangeActive="onClickItem"
    />
  </div>
</template>

<script>
export default {
  name: 'RAccordion',
  props: {
    activeItem: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItemIndex: this.$props.activeItem,
    }
  },
  methods: {
    onClickItem(itemIndex) {
      this.selectedItemIndex = this.selectedItemIndex === itemIndex ? null : itemIndex
    },
  },
}
</script>

<style scoped>
/deep/ .collapsed .r-accordion-item--content {
  display: none;
}
</style>
