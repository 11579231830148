<template>
  <div class="rreviewcardlist">
    <div
      v-if="loading"
      class="rreviewcardlist--skeleton"
    >
      <v-skeleton-loader type="card-heading" />

      <v-row class="ma-0 pa-0">
        <v-col
          v-for="i in pageSize"
          :key="`loader-${i}`"
          cols="12"
          class="rreviewcard--skeleton pa-0"
        >
          <v-divider class="dotted ma-0" />
          <v-skeleton-loader
            type="list-item-avatar-two-line, list-item-three-line"
            class="mr-6 pa-0"
          />
        </v-col>
      </v-row>
    </div>

    <div
      v-if="!loading"
      class="rreviewcardlist--content"
    >
      <div
        class="py-3 px-6 d-flex justify-space-between"
      >
        <div class="d-flex">
          <v-icon
            class="rreviewcardlist--content-close"
            @click="onClickClose"
          >
            close
          </v-icon>
          <p class="black--text rreviewcardlist--title mb-0 ">
            {{ reviewsInShortNotion }} {{ $t('resources.reviews') }}
          </p>
        </div>
        <v-menu
          v-if="showSortMenu"
          v-model="isSortDropdownOpen"
          :close-on-content-click="false"
          offset-y
          tile
          content-class="elevation-1 sectionborder"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              id="dropdownmenu"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('reviewListing.sortBy') }}
            </a>
          </template>
          <v-card
            class="mx-auto"
            max-width="300"
            tile
          >
            <v-list flat>
              <v-list-item-group
                v-model="selectedSort"
                class="mt-0"
                color="primary"
              >
                <v-list-item
                  v-for="(item, i) in sortOptions"
                  :key="i"
                  :disabled="selectedSort === i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.label" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </div>

      <v-row
        v-for="review in reviews"
        :key="review._id"
        class="ma-0 pa-0 rreviewcardlist--item"
      >
        <v-col class="pa-0">
          <v-divider class="dotted ma-0" />

          <r-review-card
            :key="review.reviewId"
            :review-id="review.reviewId"
            :search="search"
            :highlighted-entities="appliedEntities"
            :avatar-url="review.avatarUrl"
            :username="review.username"
            :date="review.date"
            :url="review.url"
            :rating="review.rating"
            :title="review.title"
            :original-title="review.originalTitle"
            :sentiment="review.sentiment"
            :language="review.language"
            :text="review.text"
            :original-text="review.originalText"
            :entities="review.entities"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="showNoResultMessage"
        class="ma-0 pa-0 rreviewcardlist--noresults"
      >
        <v-col class="pa-0">
          <v-divider class="dotted ma-0" />
          <r-no-results-message />
        </v-col>
      </v-row>
    </div>

    <r-pagination
      v-if="showPagination"
      class="py-5 pa-0"
      :page="page"
      :total-pages="totalPages"
      justify="center"
      @click:page="onPageChange"
    />
  </div>
</template>

<script>
import RReviewCard from '@/components/library/atoms/RReviewCard'
import RPagination from '@/components/library/molecules/RPagination'
import RNoResultsMessage from '@/components/library/atoms/RNoResultsMessage'

export default {
  name: 'RReviewCardList',
  components: {
    RReviewCard,
    RPagination,
    RNoResultsMessage,
  },
  props: {
    reviews: {
      type: Array,
      required: true,
    },
    totalReviews: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    appliedEntities: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isSortDropdownOpen: false,
    selectedSort: 0,
    sortOptions: [
      {
        label: 'Most Recent',
        sort: 'date',
        sortDir: 'desc',
      },
      {
        label: 'Least Recent',
        sort: 'date',
        sortDir: 'asc',
      },
      {
        label: 'Most Favorable',
        sort: 'sentiment',
        sortDir: 'desc',
      },
      {
        label: 'Most Critical',
        sort: 'sentiment',
        sortDir: 'asc',
      },
      {
        label: 'Highest Rated',
        sort: 'rating',
        sortDir: 'desc',
      },
      {
        label: 'Lowest Rated',
        sort: 'rating',
        sortDir: 'asc',
      },
      {
        label: 'Random',
        sort: 'reviewId',
        sortDir: 'desc',
      },
    ],
  }),
  computed: {
    reviewsInShortNotion() {
      const numReviews = Number(this.totalReviews)

      if (Math.abs(numReviews) >= 10000000) { return `${(Math.abs(numReviews) / 10000000).toFixed(2)}m` }
      if (Math.abs(numReviews) >= 1000) { return `${(Math.abs(numReviews) / 1000).toFixed(1)}k` }
      return Math.abs(numReviews)
    },
    showPagination() {
      return !this.loading && this.totalPages > 1
    },
    showSortMenu() {
      return this.totalReviews > 1
    },
    showNoResultMessage() {
      return this.totalReviews === 0
    },
  },
  watch: {
    selectedSort(newValue, oldValue) {
      const { sort, sortDir } = this.sortOptions[newValue]
      const { sort: oldSort, sortDir: oldSortDir } = this.sortOptions[oldValue]

      if (sort !== oldSort || sortDir !== oldSortDir) {
        this.$emit('change:sort', { value: { sort, sortDir } })
      }
    },
  },
  methods: {
    onPageChange({ value }) {
      this.$emit('change:page', { value })
    },
    onClickClose() {
      this.$emit('close')
    },
  },
}

</script>
<style scoped>
.rreviewcardlist {
  border: 1px solid var(--light-grey-color);
  min-height: 100%;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}
.rreviewcardlist--title {
  font-size: 15px !important;
  font-weight: bold;
  padding: 0;
}
.rreviewcardlist--content-close{
  padding-right: 8px;
  cursor: pointer;
}
</style>
