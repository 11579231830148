var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtimelinechart"
  }, [_vm.title ? _c('r-component-header', {
    staticClass: "rtimelinechart--title mb-4"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }