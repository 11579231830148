const SENTIMENT_BOUNDARY = 0.1

export default (sentimentScore) => {
  if (sentimentScore >= SENTIMENT_BOUNDARY) {
    return 'Positive'
  }
  if (sentimentScore <= -SENTIMENT_BOUNDARY) {
    return 'Negative'
  }
  return 'Neutral'
}
