<template>
  <v-card
    class="rreviewcard pa-5 mt-3"
    tile
    :class="{ 'rreviewcard--enabletransitions': enabletransitions }"
  >
    <div>
      <v-list-item
        two-line
        class="pa-0"
      >
        <v-list-item-avatar
          size="30"
          color="grey"
          class="pa-0 ma-0 mr-2"
        >
          <v-img :src="avatarUrl" />
        </v-list-item-avatar>

        <v-list-item-content class="pb-0">
          <p class="body-2 font-weight-bold grey--text text--darken-4 ma-0">
            {{ username }}
            <span class="rreviewcard--date grey--text">
              {{ date | moment('from', 'now') }}
            </span>
          </p>
          <v-list-item-subtitle class="text-truncate">
            {{ url }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="pa-0 py-1 mx-0 mt-1 d-flex align-end">
        <r-rating
          :value="rating"
          class="px-0 ml-0 mr-2"
        />
        <b class="black--text pt-1">{{ showOriginal ? originalTitle: title }}</b>
      </v-card-text>
      <v-card-text
        :key="`${reviewId}-${naturalMaxHeight}`"
        ref="textwrapper"
        class="black--text pa-0 rreviewcard--textwrapper my-1"
        :style="{ 'max-height': `${cardHeight}px` }"
        v-html="reviewText"
      />
      <a
        v-if="showReadMoreLink"
        class="d-block py-1"
        @click="onClickReadMore"
      >
        {{ `Read ${expandText ? 'less' : 'more'}` }}
        <v-icon
          color="primary"
          size="16"
          style="float:right;margin-top:2px"
        >
          {{ expandText ? 'expand_less' : 'expand_more' }}
        </v-icon>
      </a>
      <v-card-actions
        class="px-0 pt-3 pb-2"
        style="color:#aaa"
      >
        <v-row class="mx-0">
          <v-col
            cols="4"
            class="pa-0"
            :style="{ color: sentimentColor }"
          >
            {{ readableSentiment }}
          </v-col>
          <v-col
            cols="4"
            class="pa-0"
          >
            {{ sentiment }}
          </v-col>
          <v-col
            cols="4"
            class="pa-0"
            align="right"
          >
            <v-tooltip
              v-if="language && language !== 'en'"
              top
            >
              <template v-slot:activator="{ on }">
                <a
                  class="flag-icon"
                  :class="flagClass"
                  v-on="on"
                  @click="showOriginal = !showOriginal; addReadMore()"
                />
              </template>
              <span>Translated from {{ readableLanguage }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import debounce from '@/utils/debounce'
import RRating from '@/components/library/atoms/RRating'
import languageCodeToReadable from '@/utils/languageCodeToReadable'
import languageCodeToCountryCode from '@/utils/languageCodeToCountryCode'
import sentimentScoreToRgb from '@/utils/sentimentScoreToRgb'
import sentimentScoreToReadable from '@/utils/sentimentScoreToReadable'

const MAX_TEXT_HEIGHT = 75

const addMarkToText = ({
  originalText,
  markedText,
  word,
  offset,
  sentiment,
}) => {
  // The 'offset' is calculated based on the original text,
  // but <mark>s could be accumulated in case that match is more than 1
  // Initial offset calculated will be wrong when trying to match
  // against a text with <mark>s already added
  // To fix the offset, is required to displace given the diff with
  // the text with marks and the original text used to calculate the offset.
  const deltaOffset = markedText.length - originalText.length
  const correctedOffset = deltaOffset + offset
  const head = markedText.substr(0, correctedOffset)
  const tail = markedText.substr(correctedOffset + word.length)

  if (!sentiment && sentiment !== 0) {
    return `${head}<mark class="rreviewcard--searchterms">${word}</mark>${tail}`
  }

  const sentimentRgb = sentimentScoreToRgb(sentiment)
  return `${head}<mark style="background:${sentimentRgb}">${word}</mark>${tail}`
}

export default {
  name: 'RReviewCard',
  components: {
    RRating,
  },
  props: {
    highlightedEntities: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
    },
    avatarUrl: {
      type: String,
    },
    username: {
      type: String,
    },
    date: {
      type: String,
    },
    url: {
      type: String,
      required: false,
    },
    rating: {
      type: Number,
    },
    title: {
      type: String,
    },
    originalTitle: {
      type: String,
    },
    reviewId: {
      type: String,
      required: true,
    },
    sentiment: {
      type: Number,
    },
    language: {
      type: String,
    },
    text: {
      type: String,
    },
    originalText: {
      type: String,
    },
    entities: {
      type: Array,
      validator: (values) => {
        for (let i = 0; i < values.length; i += 1) {
          const {
            name,
            entitySentiment,
            entityTextOffset,
            extractedField,
          } = values[i]

          if (
            !(
              name
              && extractedField
              && typeof name === 'string'
              && typeof extractedField === 'string'
              && typeof entitySentiment === 'number'
              && typeof entityTextOffset === 'number'
            )
          ) return false
        }
        return true
      },
    },
  },
  data: () => ({
    showReadMoreLink: false,
    expandText: false,
    naturalMaxHeight: 99,
    enabletransitions: false,
    showOriginal: false,
  }),
  computed: {
    reviewText() {
      if (this.showOriginal) {
        return this.originalText
      }
      return this.highlightedText
    },
    cardHeight() {
      return this.expandText ? this.naturalMaxHeight : this.maxTextHeight
    },
    highlightedText() {
      const { highlightedEntities, search, text } = this
      const globalReservedStringIndexes = new Set()
      const globalHighlightMatches = new Set([])

      if (highlightedEntities.length) {
        this.highlightEntities({
          highlightedEntities,
          globalHighlightMatches,
          globalReservedStringIndexes,
        })
      }

      if (search) {
        this.highlightWord({
          word: search,
          text,
          globalHighlightMatches,
          globalReservedStringIndexes,
        })
      }

      let markedText = text
      Array.from(globalHighlightMatches)
        .sort((a, b) => a.entityTextOffset - b.entityTextOffset)
        .forEach((entity) => {
          markedText = addMarkToText({
            originalText: text,
            markedText,
            word: entity.name,
            offset: entity.entityTextOffset,
            sentiment: entity.entitySentiment,
          })
        })

      return markedText
    },
    maxTextHeight() {
      return MAX_TEXT_HEIGHT
    },
    readableLanguage() {
      const languageEntry = languageCodeToReadable[this.language]
      if (!languageEntry) {
        return this.language
      }
      return languageEntry.name
    },
    sentimentColor() {
      return sentimentScoreToRgb(this.sentiment)
    },
    readableSentiment() {
      return sentimentScoreToReadable(this.sentiment)
    },
    flagClass() {
      const flagKey = `flag-icon-${languageCodeToCountryCode(this.language)}`
      const classList = {
        active: this.showOriginal,
      }
      classList[flagKey] = true
      return classList
    },
  },
  mounted() {
    this.getNaturalHeight = (element) => {
      const currentHeight = getComputedStyle(element).height
      // eslint-disable-next-line no-param-reassign
      element.style.maxHeight = 'none'
      const { height } = getComputedStyle(element)
      // eslint-disable-next-line no-param-reassign
      element.style.maxHeight = currentHeight

      return parseInt(height.replace('px', ''), 10)
    }

    this.debouncedReadMoreHandler = debounce(() => {
      if (!this.$refs.textwrapper) {
        return
      }

      const element = this.$refs.textwrapper
      const h = this.getNaturalHeight(element)

      this.showReadMoreLink = h >= this.maxTextHeight
      this.naturalMaxHeight = h

      setTimeout(() => {
        this.enabletransitions = true
      }, 300)
    }, 50)

    this.addReadMore = () => {
      this.enabletransitions = false

      this.debouncedReadMoreHandler()
    }

    this.addReadMore()

    window.addEventListener('resize', this.addReadMore)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.addReadMore)
  },
  methods: {
    onClickReadMore() {
      this.expandText = !this.expandText
    },
    getReservedWordIndex({ word, index }) {
      const reservedStringIndexes = []
      for (let i = 0; i < word.length; i += 1) {
        reservedStringIndexes.push(index + i)
      }

      return reservedStringIndexes
    },
    highlightEntities({
      highlightedEntities,
      globalHighlightMatches,
      globalReservedStringIndexes,
    }) {
      const { entities } = this

      const entitiesToHighlight = entities
        .filter((entity) => entity.extractedField === 'text')
        .filter((el) => highlightedEntities.some((f) => el.name === f))

      entitiesToHighlight
        .forEach((entity) => {
          const { name, entityTextOffset } = entity

          if (!globalReservedStringIndexes.has(entityTextOffset)) {
            globalHighlightMatches.add(entity)
            this.getReservedWordIndex({
              word: name,
              index: entityTextOffset,
            }).forEach((index) => globalReservedStringIndexes.add(index))
          }
        })
    },
    highlightWord({
      word, text, globalHighlightMatches, globalReservedStringIndexes,
    }) {
      const reg = new RegExp(word, 'ig')
      let match = reg.exec(text)

      while (match !== null) {
        if (!globalReservedStringIndexes.has(match.index)) {
          globalHighlightMatches.add({
            name: match[0],
            entityTextOffset: match.index,
          })

          this.getReservedWordIndex({ word, index: match.index })
            .forEach((index) => globalReservedStringIndexes.add(index))
        }

        match = reg.exec(text)
      }
    },
  },
}
</script>

<style>
.rreviewcard {
  box-shadow: none !important;
}

.rreviewcard mark {
  font-weight: 500;
  padding: 2px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.rreviewcard--date {
  float: right;
}

.rreviewcard .v-card__subtitle,
.rreviewcard .v-card__text {
  font-size: 1em;
}

.rreviewcard--textwrapper {
  overflow: hidden;
}

.rreviewcard--enabletransitions .rreviewcard--textwrapper {
  transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.rreviewcard .flag-icon.active {
  filter: grayscale(100);
}

.rreviewcard--searchterms {
  background-color: var(--r-grey);
}

.rreviewcard .flag-icon-jp {
  border: 1px solid var(--r-grey);
}
</style>
