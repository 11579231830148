<template>
  <div class="rtopicwheel">
    <r-component-header>
      {{ title }}
    </r-component-header>
    <div class="rtopicwheel--chart mx-auto">
      <div
        v-if="loading"
        class="d-flex align-center justify-center rtopicwheel--chart-loader"
      >
        <v-skeleton-loader
          type="image"
          class="rounded-circle mt-3"
          :height="skeletonSize"
          :width="skeletonSize"
        />
      </div>

      <highcharts
        v-else-if="(data.length > 0) && !loading"
        :options="chartOptions()"
      />
      <div v-else>
        <p class="rtopicwheel--no-data">
          {{ $t('global.messages.noData') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import { getColorForNumber } from '@/utils/constants'

export default {
  name: 'RTopicWheel',
  components: {
    RComponentHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: false,
      validator: () => true,
    },
    hierarchical: {
      type: Boolean,
      default: false,
    },
    skeletonSize: {
      type: Number,
      required: false,
      default: 300,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSeriesData() {
      const data = []

      const addToData = (parent, parentIndex, parentValue, datum, entityGroupData) => {
        if (this.hierarchical && datum && datum.length > 0) {
          const total = data.reduce((sum, item) => sum + item.value, 0)

          datum.forEach(({ label: name, value, data: newDatum }, index) => {
            const id = `${parentIndex}.${index}`
            data.push({
              id,
              parent,
              name,
              value: this.convertValueToPercentage(total, parentValue, value),
              volume: value,
              ...entityGroupData,
            })

            addToData(id, parentIndex + 1, value, newDatum, entityGroupData)
          })
        }
      }

      for (let i = 0; i < this.data.length; i += 1) {
        const {
          label: name,
          value,
          data: datum,
          entityGroupId,
        } = this.data[i]

        const entityGroupData = {
          entityGroupId,
        }

        const id = `0.${i}`
        data.push({
          id,
          parent: '',
          name,
          value,
          color: getColorForNumber(i),
          volume: value,
          sliced: true,
          ...entityGroupData,
        })
        addToData(id, i + 1, value, datum, entityGroupData)
      }

      return data
    },
  },
  methods: {
    convertValueToPercentage(total, parentValue, value) {
      return ((value * total) / parentValue)
    },
    chartOptions() {
      const entityLabel = this.$t('resources.entity')
      const entityGroupLabel = this.$t('resources.entityGroup')
      return {
        chart: {
          type: 'sunburst',
          height: this.height,
        },
        tooltip: {
          enabled: true,
          headerFormat: '',
          formatter() {
            const { name, volume, options } = this.point
            const isEntityGroup = !!options.entityGroupId
            const label = isEntityGroup ? entityGroupLabel : entityLabel

            return `${label}: <b>${name}</b><br/>Volume: <b>${volume}</b><br/>`
          },
        },
        plotOptions: {
          sunburst: {
            innerSize: 100,
            dataLabels: {
              format: '{point.name}',
              filter: {
                property: 'innerArcLength',
                operator: '>',
                value: 16,
              },
              rotationMode: 'perpendicular',
            },
            point: {
              events: {
                click: ({ point }) => {
                  const value = {
                    label: point.name,
                    entityMapId: point.entityMapId,
                    entityGroupId: point.entityGroupId,
                    isEntityGroup: point.isEntityGroup,
                  }

                  this.onClickEntityName({ value })
                },
              },
            },
          },
        },
        series: [{
          type: 'sunburst',
          data: this.getSeriesData,
          allowDrillToNode: false,
          allowTraversingTree: false,
          cursor: 'pointer',
          levels: [
            {
              level: 1,
              colorByPoint: true,
            },
            {
              level: 2,
              colorVariation: {
                key: 'brightness',
                to: -0.5,
              },
            },
            {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.3,
              },
            },
          ],
        }],
      }
    },
    onClickEntityName({ value }) {
      this.$emit('click:entity-name', { value })
    },
  },
}
</script>

<style>
.rtopicwheel--no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
</style>
