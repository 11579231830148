var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "rstackedbarchart",
    staticClass: "rstackedbarchart"
  }, [_c('v-row', {
    staticClass: "ma-0 mb-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.data.name) + " ")])]), _vm.data.total === 0 ? _c('v-col', {
    staticClass: "pa-0 pl-2 pb-2",
    attrs: {
      "cols": "10"
    }
  }, [_c('small', [_vm._v("No matching reviews")])]) : _c('v-col', {
    staticClass: "rstackedbarchart-bar--wrapper d-flex pa-0 pl-2",
    attrs: {
      "cols": "10"
    }
  }, [_vm._l(_vm.properties, function (key) {
    return _c('div', {
      key: `bar-${key}`,
      staticClass: "rstackedbarchart-bar",
      style: {
        width: _vm.barWidth(_vm.data[key], _vm.data.total),
        'background-color': _vm.getBarBackgroundColor(key)
      },
      on: {
        "mousemove": function ($event) {
          return _vm.onMouseoverBar($event, key, _vm.data[key]);
        },
        "mouseout": function ($event) {
          return _vm.onMouseoutBar($event, key, _vm.data[key]);
        }
      }
    });
  }), _c('span', {
    staticClass: "rstackedbarchart-bar--total px-4"
  }, [_vm._v(" " + _vm._s(_vm.data.total) + " ")])], 2)], 1), _vm.tooltipLabel ? _c('div', {
    staticClass: "rstackedbarchart-tooltip",
    style: _vm.tooltipStyle,
    attrs: {
      "container": _vm.$refs.container
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.data.name))]), _c('br'), _vm._v(" " + _vm._s(`${_vm.KEY_TO_LABEL_MAP[_vm.tooltipLabel]}:`) + " "), _c('b', [_vm._v(" " + _vm._s(`${_vm.tooltipPercentage}%`) + " ")]), _vm._v(" " + _vm._s(`(${_vm.tooltipValue})`) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }