<template>
  <div class="rcomparisonchart">
    <r-component-header class="rcomparisonchart--header mb-6">
      {{ title }}
    </r-component-header>
    <v-row
      no-gutters
      align-content="space-between"
    >
      <v-col style="width: 220px">
        <div class="d-flex justify-start">
          <r-select
            :key="`rcomparisonchart--rselect`"
            class="rcomparisonchart--firstdropdown p-0 mr-2"
            :items="getSeriesOneItems"
            :show-search="false"
            :allow-empty="false"
            @change="onChangeSeriesOne"
          />
          <r-button
            class="rcomparisonchart--icons mr-1"
            color="#FFBE0A"
            small
            fab
          >
            <v-icon small>
              mdi-chart-line-variant
            </v-icon>
          </r-button>
          <r-button
            class="rcomparisonchart--icons"
            color="#4CA15A"
            fab
            small
            disabled
          >
            <v-icon small>
              mdi-poll
            </v-icon>
          </r-button>
        </div>
      </v-col>
      <v-col width="220px">
        <div class="d-flex justify-end">
          <r-button
            class="rcomparisonchart--icons mr-1"
            color="#FFBE0A"
            small
            fab
            disabled
          >
            <v-icon small>
              mdi-chart-line-variant
            </v-icon>
          </r-button>
          <r-button
            class="rcomparisonchart--icons"
            color="#4CA15A"
            fab
            small
          >
            <v-icon small>
              mdi-poll
            </v-icon>
          </r-button>
          <r-select
            :key="`rcomparisonchart--rselect`"
            class="rcomparisonchart--seconddropdown p-0 ml-2"
            :items="getSeriesTwoItems"
            :show-search="false"
            :allow-empty="false"
            @change="onChangeSeriesTwo"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-skeleton-loader
        type="image"
        width="100%"
        height="350"
      />
    </v-row>
    <v-row v-else>
      <v-col>
        <div
          :key="renderCount"
          class="rcomparisonchart-c"
        >
          <highcharts
            class="serieschart"
            :options="chartOptions()"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RSelect from '@/components/library/molecules/RSelect'
import RButton from '@/components/library/atoms/RButton'
import dateStrToDate from '@/utils/dateStrToDate'
import { COMPARISON_CHART_COLORS, COMPARISON_CHART_DATA_TYPES } from '@/utils/constants'

const COMPARISON_CHART_DATA_OPTIONS = {
  VOLUME: {
    name: 'VOLUME',
    value: 'volume',
    dataType: COMPARISON_CHART_DATA_TYPES.AGGREGATE,
  },
  AVERAGE_RATING: {
    name: 'AVERAGE_RATING',
    value: 'ratingAverage',
    dataType: COMPARISON_CHART_DATA_TYPES.AGGREGATE,
  },
  EMPTY_AGGREGATE: {
    name: 'none',
    value: 'none',
    dataType: COMPARISON_CHART_DATA_TYPES.AGGREGATE,
  },
  SENTIMENT: {
    name: 'SENTIMENT',
    value: 'sentiment',
    dataType: COMPARISON_CHART_DATA_TYPES.BREAKDOWN,
  },
  RATING_DISTRIBUTION: {
    name: 'RATING_DISTRIBUTION',
    value: 'ratingDistribution',
    dataType: COMPARISON_CHART_DATA_TYPES.BREAKDOWN,
  },
  EMPTY_BREAKDOWN: {
    name: 'none',
    value: 'none',
    dataType: COMPARISON_CHART_DATA_TYPES.BREAKDOWN,
  },
}

const SPLINE_OPTIONS_NAME = {
  VOLUME: 'Volume',
  AVERAGE_RATING: 'Average Rating',
}

export default {
  name: 'RComparisonChart',
  components: {
    RComponentHeader,
    RSelect,
    RButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
      validator: (value) => (Array.isArray(value) && value[0] && value[1]
        && Array.isArray(value[0]) && Array.isArray(value[1])),
    },
    breakdownKeys: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    seriesOneValue: 'volume',
    seriesTwoValue: 'sentiment',
    renderCount: 0,
    isSingleDayFilter: false,
  }),
  computed: {
    title() {
      const seriesOneName = this.$t(`comparisonChart.${this.seriesOneValue}`)
      const seriesTwoName = this.$t(`comparisonChart.${this.seriesTwoValue}`)

      return `${seriesOneName} vs. ${seriesTwoName}`
    },
    maxBreakdownValue() {
      const [_, breakdownData] = this.data
      const values = breakdownData
        .map(({ value }) => value.reduce((acc, v) => v + acc, 0))
        .sort((a, b) => b - a)
      const min = values[values.length - 1]
      const max = values[0]

      return {
        min,
        max,
      }
    },
    getSeriesOneItems() {
      return Object.values(COMPARISON_CHART_DATA_OPTIONS)
        .filter((option) => option.dataType === COMPARISON_CHART_DATA_TYPES.AGGREGATE)
        .filter((option) => {
          const isNoneOption = option.value === 'none'
          const noneIsAlreadySelected = this.seriesTwoValue === 'none'
          const ignoreNone = isNoneOption && noneIsAlreadySelected

          return !ignoreNone
        })
        .map((option) => ({
          label: this.$t(`comparisonChart.${option.value}`),
          value: option.value,
          selected: option.value === this.seriesOneValue,
        }))
    },
    getSeriesTwoItems() {
      return Object.values(COMPARISON_CHART_DATA_OPTIONS)
        .filter((option) => option.dataType === COMPARISON_CHART_DATA_TYPES.BREAKDOWN)
        .filter((option) => {
          const isNoneOption = option.value === 'none'
          const noneIsAlreadySelected = this.seriesOneValue === 'none'
          const ignoreNone = isNoneOption && noneIsAlreadySelected

          return !ignoreNone
        })
        .map((option) => ({
          label: this.$t(`comparisonChart.${option.value}`),
          value: option.value,
          selected: option.value === this.seriesTwoValue,
        }))
    },
    getSeries() {
      const [aggregatedData, breakdownData] = this.data

      const breakDownSeries = this.getBreakDownSeries(breakdownData)
      const splineSeries = this.getSplineSeries(aggregatedData)

      return [
        ...breakDownSeries,
        splineSeries,
      ]
    },
  },
  watch: {
    data(newProps, oldProps) {
      const [newAggregatedData, newBreakdownData] = newProps
      const [oldAggregatedData, oldBreakdownData] = oldProps

      /* eslint-disable max-len */
      const aggregateDataChanged = JSON.stringify(newAggregatedData) !== JSON.stringify(oldAggregatedData)
      const breakdownDataChanged = JSON.stringify(newBreakdownData) !== JSON.stringify(oldBreakdownData)
      /* eslint-disable max-len */

      if (aggregateDataChanged || breakdownDataChanged) {
        this.renderCount += 1
      }
    },
    breakdownKeys(newProps, oldProps) {
      if (newProps.length !== oldProps.length) {
        this.renderCount += 1
      }
    },
  },
  methods: {
    normalizers(seriesType) {
      const NORMALIZERS_BY_SERIES_TYPE = {
        ratingAverage: this.normalizeAverageRatingValue,
        default: ({ value }) => value,
      }

      return NORMALIZERS_BY_SERIES_TYPE[seriesType] || NORMALIZERS_BY_SERIES_TYPE.default
    },
    deNormalizers(seriesType) {
      const NORMALIZERS_BY_SERIES_TYPE = {
        ratingAverage: this.denormalizeAverageRatingValue,
        default: ({ value }) => value,
      }

      return NORMALIZERS_BY_SERIES_TYPE[seriesType] || NORMALIZERS_BY_SERIES_TYPE.default
    },
    denormalize(value) {
      const deNormalizerFunc = this.deNormalizers(this.seriesOneValue)
      const { min, max } = this.maxBreakdownValue
      return this.data[0].length > 1 ? deNormalizerFunc({ value, min, max }) : value
    },
    normalizeAverageRatingValue({ value, min, max }) {
      if (!min || !max || min === max) {
        return value
      }

      return this.normalizeBetweenTwoRanges({
        value,
        minVal: 0,
        maxVal: 5,
        newMin: min,
        newMax: max,
      })
    },
    denormalizeAverageRatingValue({ value, min, max }) {
      if (!min || !max || min === max) {
        return value
      }

      return this.denormalizeBetweenTwoRanges({
        value,
        minVal: 0,
        maxVal: 5,
        newMin: min,
        newMax: max,
      })
    },
    denormalizeBetweenTwoRanges({
      value, minVal, maxVal, newMin, newMax,
    }) {
      const result = (((value - newMin) / (newMax - newMin)) * (maxVal - minVal)) + minVal

      return parseFloat(result.toFixed(1))
    },
    normalizeBetweenTwoRanges({
      value, minVal, maxVal, newMin, newMax,
    }) {
      const result = ((newMax - newMin) * ((value - minVal) / (maxVal - minVal))) + newMin
      return parseFloat(result.toFixed(1))
    },
    chartOptions() {
      const series = this.getSeries
      const ctx = this

      return {
        chart: {
          type: 'column',
          spacingTop: 5,
          spacingLeft: 0,
          spacingBottom: 0,
          spacingRight: 0,
        },
        yAxis: [
          {
            labels: {
              formatter() {
                const { value } = this
                return ctx.denormalize(value)
              },
            },
            title: {
              text: ctx.$t(`comparisonChart.${ctx.seriesOneValue}`),
              enabled: true,
            },
          },
          {
            title: {
              text: ctx.$t(`comparisonChart.${ctx.seriesTwoValue}`),
              enabled: true,
            },
            opposite: true,
          },
        ],
        xAxis: {
          type: 'datetime',
        },
        legend: {
          itemMarginTop: 10,
          itemMarginBottom: 40,
          symbolHeight: 8,
          symbolWidth: 8,
          symbolPadding: 10,
          itemStyle: {
            lineHeight: '20px',
            fontWeight: '400',
            color: 'rgba(63, 63, 63, 0.75)',
          },
        },
        tooltip: {
          enabled: true,
          headerFormat: '',
          pointFormat: '{point.y}',
          formatter() {
            const { series: thisSeries, point } = this
            const { name: seriesName } = thisSeries
            const { unitName } = point.series.chart.xAxis[0].tickPositions.info

            const date = unitName === 'month' ? ctx.$moment(this.x).format("MMM 'YY") : ctx.$moment(this.x).format("D. MMM 'YY")

            if (!thisSeries.options.hasNormalizedData) {
              return `<span><b>${date}</b> <br/>${seriesName}: ${this.y}</span>`
            }

            const pointIndex = point.index
            const originalValue = thisSeries.userOptions.data[pointIndex][2]

            return `<span><b>${date}</b> <br/>${seriesName}: ${originalValue}</span>`
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            pointPadding: 0,
            groupPadding: 0,
          },
          spline: {
            lineWidth: 3,
            marker: {
              fillColor: 'white',
              lineWidth: 2,
              lineColor: null,
              radius: 5,
            },
            states: {
              hover: {
                enabled: true,
              },
            },
            events: {
              click: (e) => {
                const { point } = e
                const dataIndex = point.index
                const clickedSeries = ctx.seriesOneValue

                const [splineData, _] = this.data
                const datum = splineData[dataIndex]
                ctx.onClickSeries({ datum, clickedSeries })
              },
            },
          },
          column: {
            pointWidth: 8,
            borderWidth: 0,
            stacking: 'normal',
            keys: ['x', 'y', 'name'],
            events: {
              click: (e) => {
                const { point } = e

                const dataIndex = point.index
                const clickedValue = point.labelKey
                const clickedSeries = ctx.seriesTwoValue

                const [_, breakdownData] = this.data
                const datum = breakdownData[dataIndex]
                ctx.onClickSeries({ datum, clickedSeries, clickedValue })
              },
            },
          },
        },
        series,
      }
    },
    getBreakDownSeries(breakdownData) {
      const options = Object.values(COMPARISON_CHART_DATA_OPTIONS)
      const selectedBreakdownOption = options.find((option) => option.value === this.seriesTwoValue)

      const colors = COMPARISON_CHART_COLORS[selectedBreakdownOption.name]

      const baseSeries = []

      if (!breakdownData.length) {
        return baseSeries
      }

      const { length } = breakdownData[0].value

      for (let i = 0; i < length; i += 1) {
        baseSeries.push({
          name: this.breakdownKeys[i].label,
          color: colors[i],
          data: [],
          yAxis: 1,
        })
      }

      breakdownData.forEach(({ value, dateFrom: date }) => value.forEach(
        (v, index) => baseSeries[index].data.push(
          {
            x: dateStrToDate(date),
            y: v,
            labelKey: this.breakdownKeys[index].labelKey,
          },
        ),
      ))

      const isRatingDistribution = selectedBreakdownOption.value === COMPARISON_CHART_DATA_OPTIONS.RATING_DISTRIBUTION.value

      if (isRatingDistribution) {
        return baseSeries.reverse()
      }

      return baseSeries
    },
    getSplineSeries(aggregatedData) {
      const options = Object.values(COMPARISON_CHART_DATA_OPTIONS)
      const selectedSplineOption = options.find((option) => option.value === this.seriesOneValue)

      if (!aggregatedData.length) {
        return { showInLegend: selectedSplineOption.name !== 'none' }
      }

      const { min, max } = this.maxBreakdownValue
      const normalizerFunc = this.normalizers(this.seriesOneValue)
      const normalizedData = aggregatedData.map(({ value, dateFrom: date }) => [
        dateStrToDate(date),
        aggregatedData.length > 1 ? normalizerFunc({ value, min, max }) : value,
        value,
      ])

      let { isSingleDayFilter } = this
      const isSingleAggregatedDataItem = aggregatedData.length === 1

      if (isSingleAggregatedDataItem) {
        const [singleAggregatedDataItem] = aggregatedData
        const { dateFrom, dateTo } = singleAggregatedDataItem
        isSingleDayFilter = dateStrToDate(dateFrom) === dateStrToDate(dateTo)
      }

      return {
        showInLegend: true,
        name: SPLINE_OPTIONS_NAME[selectedSplineOption.name],
        color: COMPARISON_CHART_COLORS[selectedSplineOption.name][0],
        type: 'spline',
        hasNormalizedData: true,
        data: normalizedData,
        marker: {
          enabled: isSingleDayFilter,
        },
      }
    },
    onChangeSeriesOne({ value }) {
      this.seriesOneValue = value
      this.$emit('change:series-one', {
        value: { type: COMPARISON_CHART_DATA_TYPES.AGGREGATE, key: value },
      })
    },
    onChangeSeriesTwo({ value }) {
      this.seriesTwoValue = value
      this.$emit('change:series-two', {
        value: { type: COMPARISON_CHART_DATA_TYPES.BREAKDOWN, key: value },
      })
    },
    onClickSeries({ datum, clickedSeries, clickedValue }) {
      const { dateFrom, dateTo } = datum

      const eventValue = {
        clickedValue,
        clickedSeries,
        dateFrom,
        dateTo,
      }

      this.$emit('click:series', { value: eventValue })
    },
  },
}

</script>

<style scoped>
.rcomparisonchart--header {
  border-bottom: 1px solid var(--r-border-color);
  padding-bottom: 8px;
}
.rcomparisonchart--firstdropdown {
  width: 160px;
}
.rcomparisonchart--seconddropdown {
  width: 160px;
}
.rcomparisonchart--icons {
  margin-top: 3px;
  border-radius: 50%;
  color: white
}
.v-btn--fab.v-size--small {
  height: 24px;
  width: 24px;
}
</style>
