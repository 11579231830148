<template>
  <div class="rratingtimelinechart">
    <r-component-header
      v-if="title"
      class="rratingtimelinechart--title mb-4"
    >
      {{ title }}
    </r-component-header>
    <v-skeleton-loader
      v-if="loading"
      class="py-5"
      type="image"
      width="100%"
      height="200"
    />
    <div
      v-else
      class="rratingtimelinechart--container"
    >
      <highcharts
        ref="chart"
        :options="chartOptions"
        class="hc"
      />
      <div class="rratingtimelinechart--last-rating">
        <span class="rratingtimelinechart--last-rating-top">
          {{ lastValue }}
        </span>
        <span class="rratingtimelinechart--last-rating-slash">/</span>
        <span class="rratingtimelinechart--last-rating-bottom">
          {{ maxRateValue }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import dateStrToDate from '@/utils/dateStrToDate'

const COLOR = '#FBC02D'
const starImg = require('@/assets/images/star.svg')

const verticalGapBetweenStars = 26
const horizontalGapBetweenStars = 20
const starWidth = 12
const starHeight = 12
const gapBetweenGridLines = 30

export default {
  name: 'RRatingTimeLineChart',
  components: {
    RComponentHeader,
  },
  props: {
    maxRateValue: {
      type: Number,
      default: 5,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      height: this.maxRateValue * gapBetweenGridLines,
    }
  },
  computed: {
    hasSingleDataPoint() {
      return this.data.length === 1
    },
    lastValue() {
      return this.data.length && this.data[this.data.length - 1].value
    },
    chartOptions() {
      const vm = this

      return {
        chart: {
          height: this.$data.height || null,
          spacing: [10, 0, 10, 0],
          events: {
            render() {
              vm.generateIcons().forEach((
                {
                  width,
                  height,
                  x,
                  y,
                  src,
                  rating,
                },
              ) => {
                this.renderer
                  .image(src, x, y, width, height)
                  .addClass('clickable')
                  .on('click', () => vm.onClickRating({ value: rating }))
                  .add()
              })
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            enabled: false,
          },
          min: 0,
          max: 5,
          tickInterval: 1,
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false,
          },
          minPadding: this.maxRateValue * 0.11,
          maxPadding: this.maxRateValue * 0.11,
        },
        tooltip: {
          enabled: true,
          headerFormat: '',
          pointFormat: '{point.y}',
        },
        series: [{
          type: 'line',
          color: COLOR,
          showInLegend: false,
          data: this.data.map((series) => [dateStrToDate(series.date), series.value]),
          marker: {
            enabled: this.hasSingleDataPoint,
          },
        }],
        plotOptions: {
          series: {
            cursor: 'pointer',
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      }
    },
  },
  methods: {
    generateIcons() {
      let currentRating = this.maxRateValue
      const items = []
      const src = starImg
      let x = 6
      let y = 16.5

      while (currentRating > 0) {
        if (currentRating !== this.maxRateValue) {
          y += verticalGapBetweenStars
          x = 6
        }
        for (let i = 1; i <= currentRating; i += 1) {
          items.push({
            src,
            x,
            y,
            width: starWidth,
            height: starHeight,
            rating: currentRating,
          })
          x += horizontalGapBetweenStars
        }
        currentRating -= 1
      }

      return items
    },
    onClickRating({ value }) {
      this.$emit('click:rating', { value })
    },
  },
}
</script>

<style scoped>
 .rratingtimelinechart--title {
   font-weight: 700;
   font-size: 14px;
   line-height: 20px;
 }

 .rratingtimelinechart--container{
  position: relative;
  margin-right: 25px;
 }
.rratingtimelinechart--last-rating {
  position: absolute;
  top: 8px;
  right: -25px;
}
.rratingtimelinechart--last-rating-top {
  font-weight: 500;
  font-size: 32px;
  color: #3F3F3F;
  letter-spacing: -0.016px;
}
.rratingtimelinechart--last-rating-slash {
  font-weight: 400;
  font-size: 32px;
  letter-spacing: -0.016px;
  color: var(--r-border-color)
}
.rratingtimelinechart--last-rating-bottom {
  font-weight: 400;
  font-size: 13px;
  color: var(--r-border-color)
}
/deep/ .highcharts-grid path.highcharts-grid-line:last-child,
/deep/ .highcharts-grid path.highcharts-grid-line:first-child {
  stroke: none!important;
}
</style>
