<template>
  <div
    ref="rstackedbarchart"
    class="rstackedbarchart"
  >
    <v-row class="ma-0 mb-0">
      <v-col
        cols="2"
        class="pa-0"
      >
        <h4 class="text-truncate">
          {{ data.name }}
        </h4>
      </v-col>
      <v-col
        v-if="data.total === 0"
        cols="10"
        class="pa-0 pl-2 pb-2"
      >
        <small>No matching reviews</small>
      </v-col>
      <v-col
        v-else
        cols="10"
        class="rstackedbarchart-bar--wrapper d-flex pa-0 pl-2"
      >
        <div
          v-for="key in properties"
          :key="`bar-${key}`"
          class="rstackedbarchart-bar"
          :style="{
            width: barWidth(data[key], data.total),
            'background-color': getBarBackgroundColor(key),
          }"
          @mousemove="onMouseoverBar($event, key, data[key])"
          @mouseout="onMouseoutBar($event, key, data[key])"
        />
        <span class="rstackedbarchart-bar--total px-4">
          {{ data.total }}
        </span>
      </v-col>
    </v-row>
    <div
      v-if="tooltipLabel"
      class="rstackedbarchart-tooltip"
      :style="tooltipStyle"
      :container="$refs.container"
    >
      <b>{{ data.name }}</b>
      <br>
      {{ `${KEY_TO_LABEL_MAP[tooltipLabel]}:` }}
      <b> {{ `${tooltipPercentage}%` }} </b>
      {{ `(${tooltipValue})` }}
    </div>
  </div>
</template>

<script>
import chartKeyToColor from '@/utils/chartKeyToColor'
import getPercentage from '@/utils/getPercentage'

const MAX_BAR_PERCENTAGE = 100
const KEY_TO_LABEL_MAP = {
  one: '1 Star',
  two: '2 Stars',
  three: '3 Stars',
  four: '4 Stars',
  five: '5 Stars',
  positive: 'Positive',
  neutral: 'Neutral',
  negative: 'Negative',
}

export default {
  name: 'RStackedBarChart',
  props: {
    properties: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tooltipLabel: '',
    tooltipValue: 0,
    tooltipPosition: {
      top: 0,
      left: 0,
    },
    KEY_TO_LABEL_MAP,
  }),
  computed: {
    tooltipPercentage() {
      const { total } = this.$props.data
      return (getPercentage({ value: this.tooltipValue, total }) * 100).toFixed()
    },
    tooltipStyle() {
      return {
        top: this.tooltipPosition.top,
        left: this.tooltipPosition.left,
        'border-color': chartKeyToColor(this.tooltipLabel),
      }
    },
  },
  methods: {
    getBarBackgroundColor(key) {
      return chartKeyToColor(key)
    },
    setTooltipPosition(event) {
      const { clientX, clientY } = event
      const bb = this.$refs.rstackedbarchart.getBoundingClientRect()
      this.tooltipPosition = {
        top: `${clientY - bb.top - 10}px`,
        left: `${clientX - bb.left}px`,
      }
    },
    barWidth(v, total) {
      return `${(v / total) * MAX_BAR_PERCENTAGE}%`
    },
    onMouseoverBar(e, key, value) {
      this.tooltipLabel = key
      this.tooltipValue = value
      this.setTooltipPosition(e)
    },
    onMouseoutBar() {
      this.tooltipLabel = ''
      this.tooltipValue = 0
      this.tooltipPosition = {
        top: 0,
        left: 0,
      }
    },
  },
}
</script>

<style scoped>
.rstackedbarchart {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}
.rstackedbarchart-bar {
  display: inline-block;
  height: 25px;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.rstackedbarchart-bar--total {
  width: 72px;
  align-self: center;
  text-align: end;
}

.rstackedbarchart-tooltip {
  position: absolute;
  margin-top: -35px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9) !important;
  white-space: nowrap;
  padding: 6px 12px;
  border-radius: 3px;
  pointer-events: none;
  font-size: 12px;
  border: 2px solid #999;
}
</style>
