const formatBigNumber = (num) => {
  if (num > 999 && num < 1000000) {
    return `${parseFloat((num / 1000).toFixed(1))}K` // convert to K for number from > 1000 < 1 million
  }

  if (num > 1000000) {
    return `${parseFloat((num / 1000000).toFixed(1))}M` // convert to M for number from > 1 million
  }
  return num
}

export default formatBigNumber
