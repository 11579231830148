<template>
  <div class="rbreadcrumbs-container">
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>

      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="breadcrumbs-item"
          active-class="active"
          :to="item.routePath"
          :disabled="!!item.disabled"
        >
          {{ item.label }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'RBreadcrumbs',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped>
/deep/ .v-breadcrumbs {
  padding: 0
}

/deep/ .breadcrumbs-item a {
  font-style: normal;
  font-weight: 400;
  color: #514848
}

/deep/ .breadcrumbs-item .active {
  font-weight: 700;
}

</style>
