var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "topurl",
    staticClass: "rtopurls"
  }, [_c('r-tabs', {
    attrs: {
      "background-color": "transparent",
      "items": _vm.tabs,
      "selected-tab": _vm.selectedTab
    },
    on: {
      "change": _vm.onChangeTab
    }
  }), _c('r-tab-items', {
    attrs: {
      "selected-tab": _vm.selectedTab
    }
  }, [_vm._t("default", function () {
    return _vm._l(_vm.tabItems, function (item) {
      return _c('r-tab-item', {
        key: item.label,
        attrs: {
          "label": item.label
        }
      }, [_vm._t("default", function () {
        return [_c('table', {
          staticClass: "rtopurls--table"
        }, [_c('thead', {
          staticClass: "rtopurls--table-header"
        }, [_c('tr', [_c('th', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('topUrls.urls')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.$t('topUrls.starRating')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.$t('topUrls.reviewVolume')) + " ")])])]), _c('tbody', {
          staticClass: "rtopurls--table-body"
        }, _vm._l(item.content, function (datum, index) {
          return _c('tr', {
            key: datum.id
          }, [_c('td', {
            staticClass: "rtopurls--table-sr"
          }, [_vm._v(" " + _vm._s(index + 1) + ". ")]), _c('td', {
            staticClass: "rtopurls--table-name"
          }, [_c('r-data-image', {
            attrs: {
              "size": 24,
              "ids": ['https://i.picsum.photos/id/732/510/300.jpg?hmac=QZJQGU9iS24mIgx_75CDQbfzjceydJS5mHNEUXVampE']
            }
          }), _c('span', {
            staticClass: "rtopurls--table-item-name d-block text-truncate",
            style: _vm.textStyle
          }, [_vm._v(" " + _vm._s(datum.name) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm.formatNumber(datum.averageRating)) + " "), _c('v-icon', {
            staticClass: "rtopurls--table-star"
          }, [_vm._v(" mdi-star ")])], 1), _c('td', [_vm._v(" " + _vm._s(datum.numReviews) + " ")])]);
        }), 0)])];
      })], 2);
    });
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }