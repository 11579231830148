<template>
  <v-navigation-drawer
    class="rnavigationdrawer"
    permanent
    expand-on-hover
  >
    <div v-if="title || subtitle">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            v-if="title"
            class="text-h6"
          >
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="subtitle">
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
    </div>

    <v-list
      v-if="items.length"
      dense
      nav
    >
      <v-list-group
        v-for="directory in directories"
        :key="directory.title"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="directory.title" />
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="child in directory.children"
          :key="child.title"
          link
          @click="onClickItem(child)"
        >
          <v-list-item-icon>
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list-group>

      <v-list-item
        v-for="item in singleItems"
        :key="item.title"
        link
        @click="onClickItem(item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
function sortItemsByTitle(items) {
  return [...items].sort((a, b) => a.title.localeCompare(b.title))
}

export default {
  name: 'RNavigationDrawer',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    directories() {
      return this.items.filter((item) => item.children).map((item) => {
        const sortedChildren = sortItemsByTitle(item.children)
        return {
          ...item,
          children: sortedChildren,
        }
      })
    },
    singleItems() {
      const items = this.items.filter((item) => item.ref)
      return sortItemsByTitle(items)
    },
  },
  methods: {
    onClickItem(item) {
      this.$vuetify.goTo(item.ref)
    },
  },
}
</script>
