<template>
  <div class="rtimelinechart">
    <r-component-header
      v-if="title"
      class="rtimelinechart--title mb-4"
    >
      {{ title }}
    </r-component-header>
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import dateStrToDate from '@/utils/dateStrToDate'
import {
  COLORS,
  getColorForNumber,
} from '@/utils/constants'

export default {
  name: 'RTimeLineChart',
  components: {
    RComponentHeader,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    spline: {
      type: Boolean,
      default: false,
    },
    durationGap: {
      type: String,
      default: 'days',
    },
    data: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 0,
    },
    yAxisLabel: {
      type: String,
      default: '',
    },
    xAxisLabel: {
      type: String,
      default: 'Time',
    },
  },
  data() {
    return {
      lineType: this.spline ? 'spline' : 'line',
    }
  },
  computed: {
    chartOptions() {
      const { height } = this.$props
      const vm = this
      return {
        chart: {
          height,
          spacingBottom: 0,
          spacingTop: 5,
          spacingLeft: 0,
          spacingRight: 0,
        },
        yAxis: this.data.map((datum, i) => ({
          title: {
            text: datum.label ?? this.yAxisLabel,
            align: 'high',
            rotation: 0,
            y: -20,
            offset: 10,
            style: {
              fontWeight: 'bold',
            },
          },
          opposite: i !== 0,
        })),
        xAxis: {
          title: {
            text: this.xAxisLabel,
            y: -20,
            x: 30,
            align: 'high',
            style: {
              fontWeight: 'bold',
            },
          },
          type: 'datetime',
          lineWidth: 2,
          tickLength: 0,
        },
        tooltip: {
          enabled: true,
          headerFormat: '',
          pointFormat: '{point.y}',
        },
        series: this.data.map((datum, i) => ({
          yAxis: i,
          name: datum.label,
          color: this.data.length > 1 ? getColorForNumber(i) : COLORS.BLUE,
          type: this.data.length === 1 || !datum.chartType ? this.lineType : datum.chartType,
          showInLegend: false,
          data: datum.seriesData.map((series) => [dateStrToDate(series.date), series.value]),
          marker: {
            enabled: datum.seriesData.length === 1,
          },
        })),
        plotOptions: {
          series: {
            cursor: 'pointer',
            states: {
              hover: {
                enabled: this.toolTipVisible,
              },
            },
            point: {
              events: {
                click: ({ point }) => {
                  const { category } = point
                  let startDate
                  let endDate

                  if (this.durationGap === 'months') {
                    startDate = vm.$moment(category).startOf('month').format('YYYY-MM-DD')
                    endDate = vm.$moment(category).format('YYYY-MM-DD')
                  } else {
                    startDate = vm.$moment(category).format('YYYY-MM-DD')
                    endDate = startDate
                  }
                  const value = [startDate, endDate]
                  this.$emit('click:point', { value })
                },
              },
            },
          },
        },
      }
    },
  },
}
</script>
