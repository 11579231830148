var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rsbch"
  }, [_vm.title ? _c('r-component-header', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "pa-1",
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "400"
    }
  }) : _vm.data.length > 0 && !_vm.loading ? _c('div', [_c('highcharts', {
    staticClass: "rsbch--chart",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1) : _c('div', [_c('p', {
    staticClass: "rsbch--no-data"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.messages.noData')) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }