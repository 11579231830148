<template>
  <div class="rmap">
    <v-skeleton-loader
      v-if="loading"
      class="pa-1"
      type="image"
      width="100%"
      height="500"
    />
    <highcharts
      v-else
      :constructor-type="'mapChart'"
      :options="mapOptions"
    />
  </div>
</template>

<script>
import { getColorForNumber } from '@/utils/constants'

export default {
  name: 'RMap',
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mapOptions() {
      const ctx = this

      return {
        chart: {
          map: 'countryVolume',
        },
        mapNavigation: {
          enabled: true,
          enableButtons: false,
        },
        mapView: {
          padding: '15',
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          map: {
            dataLabels: {
              enabled: false,
            },
          },
          series: {
            dataLabels: {
              allowOverlap: true,
            },
            events: {
              click(e) {
                const { point } = e
                const clickedCountry = point.name

                ctx.onClickCountry({ clickedCountry })
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: 'white',
          borderWidth: 0,
          shadow: false,
          formatter() {
            return `Country: ${this.point.name}<br>Volume: ${this.point.value}`
          },
        },
        series: [{
          type: 'map',
          joinBy: ['iso-a3', 'code'],
          cursor: 'pointer',
          states: {
            hover: {
              borderWidth: 1,
            },
          },
          dataLabels: {
            enabled: true,
            formatter() {
              const { name: countryName, value: volume } = this.point

              if (volume === null) {
                return null
              }
              return ctx.customCountryName(countryName)
            },
          },
          data: this.data.map(({ label, value }) => ({
            code: label,
            color: getColorForNumber(value),
            value,
          })),
        }],
      }
    },
  },
  methods: {
    customCountryName(name) {
      const COUNTRY_NAME_MAP = {
        'United States of America': 'United States',
      }

      return COUNTRY_NAME_MAP[name] || name
    },
    onClickCountry({ clickedCountry }) {
      const value = { clickedCountry: this.customCountryName(clickedCountry) }
      this.$emit('click:country', { value })
    },
  },
}
</script>
