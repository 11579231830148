<template>
  <div class="rcomponentheader">
    <slot />
    <div class="rcomponentheader--end">
      <slot name="end" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'RComponentHeader',
}
</script>

<style scoped>
.rcomponentheader {
  font-style: normal;
  font-weight: 700;
  font-size: 1.07692307692rem;
  line-height: 20px;
  letter-spacing: -0.02px;
  color: var(--r-dark-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
