<template>
  <p class="pa-4 ma-0">
    {{ $t('global.messages.noResults') }}
  </p>
</template>

<script>
export default {
  name: 'RNoResultsMessage',
}
</script>
