var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rbreadcrumbs-container"
  }, [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          staticClass: "breadcrumbs-item",
          attrs: {
            "active-class": "active",
            "to": item.routePath,
            "disabled": !!item.disabled
          }
        }, [_vm._v(" " + _vm._s(item.label) + " ")])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }