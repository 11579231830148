var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtrends"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('r-component-header', {
    staticClass: "mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('rte.trends')) + " ")]), _c('r-select', {
    staticClass: "flex-grow-1 font-weight-regular",
    attrs: {
      "allow-empty": false,
      "items": _vm.items,
      "show-search": false,
      "width": "100%"
    },
    on: {
      "change": _vm.emitInterValChange
    }
  })], 1), _vm.loading ? _c('div', [_c('v-skeleton-loader', {
    staticClass: "rtrends--mt elevation-0 dataalertscards--loader",
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "50"
    }
  }), _c('v-skeleton-loader', {
    staticClass: "rtrends--mt elevation-0 dataalertscards--loader",
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "50"
    }
  })], 1) : [_c('div', {
    staticClass: "rtrends--mt"
  }, [_c('div', {
    staticClass: "rtrends--chart-container",
    on: {
      "click": function ($event) {
        return _vm.onClickContainerArea({
          type: _vm.TYPES.POSITIVE
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', [_c('p', {
    staticClass: "rtrends--positive-text ma-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.condition.positive')) + " ")])]), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "rtrends--positive-value ma-0"
  }, [_vm._v(" " + _vm._s(_vm.totalPositive) + " ")])])]), _c('div', {
    staticClass: "rtrends--highcharts-wrapper"
  }, [_c('highcharts', {
    ref: "chart-positive",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "options": _vm.chartOptionsPositive
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', [_vm.positivePercentIncrease >= 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/images/trendingEntities/green-arrow-up.svg"),
      "alt": "arrow"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/images/trendingEntities/green-arrow-down.svg"),
      "alt": "arrow"
    }
  })]), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "rtrends--positive-percent"
  }, [_vm._v(" " + _vm._s(Math.floor(_vm.positivePercentIncrease)) + "% ")])])])]), _c('div', {
    staticClass: "d-flex justify-center align-center mt-2"
  }, [_vm.positiveEntities.length > 0 ? _c('div', [_c('img', {
    staticClass: "rtrends--fire-icons",
    attrs: {
      "src": require("@/assets/images/trendingEntities/fire.svg"),
      "alt": "fire"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickContainerArea({
          type: _vm.TYPES.POSITIVE
        });
      }
    }
  })]) : _vm._e(), _c('div', [_c('p', {
    staticClass: "rtrends--fire-positive"
  }, _vm._l(_vm.positiveEntities, function (entity, index) {
    return _c('span', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.onClickEntity({
            type: _vm.TYPES.POSITIVE,
            entity
          });
        }
      }
    }, [_vm._v(" " + _vm._s(entity) + _vm._s(index !== _vm.positiveEntities.length - 1 ? ';' : '') + " ")]);
  }), 0)])])]), _c('div', {
    staticClass: "rtrends--mt"
  }, [_c('div', {
    staticClass: "rtrends--chart-container",
    on: {
      "click": function ($event) {
        return _vm.onClickContainerArea({
          type: _vm.TYPES.NEGATIVE
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', [_c('p', {
    staticClass: "rtrends--negative-text ma-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.condition.negative')) + " ")])]), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "rtrends--negative-value ma-0"
  }, [_vm._v(" " + _vm._s(_vm.totalNegative) + " ")])])]), _c('div', {
    staticClass: "rtrends--highcharts-wrapper"
  }, [_c('highcharts', {
    ref: "chart-negative",
    attrs: {
      "options": _vm.chartOptionsNegative
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', [_vm.negativePercentIncrease > 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/images/trendingEntities/red-arrow-up.svg"),
      "alt": "arrow"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/images/trendingEntities/red-arrow-down.svg"),
      "alt": "arrow"
    }
  })]), _c('div', {
    staticClass: "ml-4"
  }, [_c('p', {
    staticClass: "rtrends--negative-percent"
  }, [_vm._v(" " + _vm._s(Math.floor(_vm.negativePercentIncrease)) + "% ")])])])]), _c('div', {
    staticClass: "d-flex justify-center align-center mt-2"
  }, [_vm.negativeEntities.length > 0 ? _c('div', [_c('img', {
    staticClass: "rtrends--fire-icons",
    attrs: {
      "src": require("@/assets/images/trendingEntities/red-fire.svg"),
      "alt": "arrow"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickContainerArea({
          type: _vm.TYPES.NEGATIVE
        });
      }
    }
  })]) : _vm._e(), _c('div', [_c('p', {
    staticClass: "rtrends--fire-negative"
  }, _vm._l(_vm.negativeEntities, function (entity, index) {
    return _c('span', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.onClickEntity({
            type: _vm.TYPES.NEGATIVE,
            entity
          });
        }
      }
    }, [_vm._v(" " + _vm._s(entity) + _vm._s(index !== _vm.negativeEntities.length - 1 ? ';' : '') + " ")]);
  }), 0)])])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }