const MAX_LENGTH = 2500
const ANGLE_COEFF = 0.1
const WORD_PADDING = [3, 6]

const gridPositionEmpty = ({
  grid, x, y, width, height,
}) => {
  const [padY, padX] = WORD_PADDING
  const startX = x - padX
  const startY = y - padY
  for (let i = startX; i < x + width + padX; i += 1) {
    if (i < 0 || i >= grid.length) {
      return false
    }
    for (let j = startY; j < y + height + padY; j += 1) {
      if (y < 0 || y >= grid[i].length) {
        return false
      }
      if (!grid[i]) {
        return false
      }
      if (grid[i][j] > 0) {
        return false
      }
    }
  }
  return true
}

function generateSpiralCoords(el) {
  const { width, height } = el.getBoundingClientRect()
  const coords = []
  const aspect = width / height
  for (let i = 0; i < MAX_LENGTH; i += 1) {
    const angle = i * ANGLE_COEFF
    let x = Math.floor((width / 2) + angle * Math.sin(angle))
    const y = Math.floor((height / 2) + angle * Math.cos(angle))
    if (y < 0 || x < 0 || y > height || x > width) {
      // eslint-disable-next-line no-continue
      continue
    }
    const adjustX = Math.floor((x - (width / 2)) * aspect)
    x += adjustX
    const numCoords = coords.length
    if (numCoords === 0) {
      coords.push({ x, y })
    } else if (coords[numCoords - 1].x !== x && coords[numCoords - 1].y !== y) {
      coords.push({ x, y })
    }
  }
  return coords
}

function calculateWordPixelSize({
  volume,
  minVolume,
  maxVolume,
  minWordSize,
  maxWordSize,
}) {
  const percentOfMax = ((volume - minVolume) / (maxVolume - minVolume))
  return (percentOfMax * (maxWordSize - minWordSize)) + minWordSize
}

function createEmptyGrid(el) {
  const { width, height } = el.getBoundingClientRect()
  const arr = []
  for (let i = 0; i < width; i += 1) {
    arr[i] = []
    for (let j = 0; j < height; j += 1) {
      arr[i][j] = 0
    }
  }
  return arr
}

function findPosition({
  spiralCoords,
  grid,
  width,
  height,
}) {
  return spiralCoords.find(({ x, y }) => {
    if (x + width > grid.length - 1) {
      return false
    }
    if (!grid[0]) {
      return false
    }
    if (y + height > grid[0].length - 1) {
      return false
    }
    return gridPositionEmpty({
      grid, x, y, width, height,
    })
  })
}

function measureWord(el) {
  const { width, height } = el.getBoundingClientRect()
  return {
    width: Math.ceil(width),
    height: Math.ceil(height),
  }
}

exports.calculateWordPixelSize = calculateWordPixelSize
exports.createEmptyGrid = createEmptyGrid
exports.findPosition = findPosition
exports.generateSpiralCoords = generateSpiralCoords
exports.measureWord = measureWord
