<template>
  <div
    ref="topurl"
    class="rtopurls"
  >
    <r-tabs
      background-color="transparent"
      :items="tabs"
      :selected-tab="selectedTab"
      @change="onChangeTab"
    />

    <r-tab-items
      :selected-tab="selectedTab"
    >
      <slot>
        <r-tab-item
          v-for="item in tabItems"
          :key="item.label"
          :label="item.label"
        >
          <slot>
            <table
              class="rtopurls--table"
            >
              <thead class="rtopurls--table-header">
                <tr>
                  <th colspan="2">
                    {{ $t('topUrls.urls') }}
                  </th>
                  <th> {{ $t('topUrls.starRating') }} </th>
                  <th> {{ $t('topUrls.reviewVolume') }} </th>
                </tr>
              </thead>

              <tbody class="rtopurls--table-body">
                <tr
                  v-for="(datum, index) in item.content"
                  :key="datum.id"
                >
                  <td class="rtopurls--table-sr">
                    {{ index + 1 }}.
                  </td>
                  <td class="rtopurls--table-name">
                    <r-data-image
                      :size="24"
                      :ids="['https://i.picsum.photos/id/732/510/300.jpg?hmac=QZJQGU9iS24mIgx_75CDQbfzjceydJS5mHNEUXVampE']"
                    />
                    <span
                      class="rtopurls--table-item-name d-block text-truncate"
                      :style="textStyle"
                    >
                      {{ datum.name }}
                    </span>
                  </td>
                  <td>
                    {{ formatNumber(datum.averageRating) }}
                    <v-icon class="rtopurls--table-star">
                      mdi-star
                    </v-icon>
                  </td>
                  <td> {{ datum.numReviews }} </td>
                </tr>
              </tbody>
            </table>
          </slot>
        </r-tab-item>
      </slot>
    </r-tab-items>
  </div>
</template>

<script>
import RTabs from '@/components/library/molecules/RTabs'
import RTabItems from '@/components/library/molecules/RTabItems'
import RTabItem from '@/components/library/molecules/RTabItem'
import RDataImage from '@/components/library/atoms/RDataImage'

export default {
  name: 'RTopUrls',
  components: {
    RTabItem,
    RTabs,
    RTabItems,
    RDataImage,
  },
  props: {
    highestRated: {
      type: Array,
      required: false,
    },
    mostReviewed: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      textWidth: 0,
      tabs: [
        { label: this.$t('topUrls.highestRated'), key: 'highest-rated' },
        { label: this.$t('topUrls.mostReviewed'), key: 'most-reviewed' },
      ],
      selectedTab: 0,
    }
  },
  computed: {
    tabItems() {
      return [
        {
          label: this.$t('topUrls.highestRated'),
          content: this.highestRated,
        },
        {
          label: this.$t('topUrls.mostReviewed'),
          content: this.mostReviewed,
        },
      ]
    },
    textStyle() {
      return `width: ${this.textWidth}px`
    },
  },
  mounted() {
    this.calculateTextWidth()
    window.addEventListener('resize', this.calculateTextWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateTextWidth)
  },
  methods: {
    calculateTextWidth() {
      const { clientWidth } = this.$refs.topurl
      this.textWidth = clientWidth - 260
    },
    onChangeTab(selectedTabIndex) {
      this.selectedTab = selectedTabIndex
    },
    formatNumber(num) {
      return num ? num.toFixed(2) : 0
    },
  },
}
</script>

<style scoped>
/deep/.v-tabs {
  border: none;
}

/deep/.v-tab {
  border: none;
  background-color: white;
  color: #3F3F3F;
  padding-bottom: 1px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

/deep/.v-tab--active {
  background-color: white;
  border: none !important;
  border-bottom: 1px solid #1691CF !important;
  color: #1691CF;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.rtopurls {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #1691CF
}

.rtopurls--table {
  width: 100%;
  min-width: 358px;
  border-collapse: collapse;
}

.rtopurls--table-header {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #3F3F3F;
  text-align: left;
}

.rtopurls--table-header th {
  padding: 14px 10px;
}

.rtopurls--table-body td {
  padding: 18px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  background-color: #F8F8F8;
  border-bottom: 2px solid white;
}

.rtopurls--table-sr {
  width: 32px;
}

.rtopurls--table-name {
  font-size: 12px;
  font-weight: 700;
  display: flex;
}
.rtopurls--table-item-name {
  padding-left: 5px;
  line-height: 28px;
}
.rtopurls--table-star {
  padding-bottom: 3px;
  margin-right: 5px;
  color: #FBC02D;
}
</style>
