<template>
  <div class="rbignumber">
    <r-component-header>{{ title }}</r-component-header>
    <div class="d-flex align-center mt-4">
      <div class="d-flex box">
        <v-skeleton-loader
          v-if="loading"
          class="rbignumber--loader"
          type="chip"
        />
        <div
          v-else
          class="rbignumber--short-number"
          :title="value"
        >
          {{ shortNumber }}
        </div>
        <template v-if="previousValue">
          <v-divider
            class="divider-line"
            vertical
          />
          <v-skeleton-loader
            v-if="loading"
            type="chip"
          />
          <div v-else>
            <v-icon :class="getIconName.replace('_', '-')">
              {{ getIconName }}
            </v-icon>

            <div
              class="rbignumber--previous-period-value"
              :title="previousValue"
            >
              {{ $t('bigNumber.previousPeriod') }}: {{ shortPreviousValue }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import formatBigNumber from '@/utils/formatBigNumber'
import debounce from '@/utils/debounce'

export default {
  name: 'RBigNumber',
  components: {
    RComponentHeader,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    previousValue: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    parentWidth: 0,
    numberWidth: 0,
    childComponents: [],
    parentComponents: [],
    debouncedOnResize: null,
  }),
  computed: {
    getIconName() {
      if (this.$props.value > this.$props.previousValue) {
        return 'trending_up'
      }

      if (this.$props.value === this.$props.previousValue) {
        return 'trending_flat'
      }
      return 'trending_down'
    },
    shortNumber() {
      return this.checkNumber(this.$props.value)
    },
    shortPreviousValue() {
      return this.checkNumber(this.$props.previousValue)
    },
  },
  mounted() {
    this.$data.debouncedOnResize = debounce(() => this.onResize(), 200)
    window.addEventListener('resize', this.$data.debouncedOnResize)
    this.calculateWidths()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$data.debouncedOnResize)
  },
  methods: {
    calculateWidths() {
      const scrollBarWidth = window.innerWidth - document.body.clientWidth
      this.childComponents = document.querySelectorAll('.box')
      this.parentComponents = document.querySelectorAll('.r-big-number')
      this.parentWidth = this.$el.clientWidth

      this.childComponents.forEach((p) => {
        this.numberWidth = p.clientWidth + scrollBarWidth
      })
    },
    onResize() {
      const parentWidthArray = [...this.parentComponents].map((p) => p.clientWidth)
      this.parentWidth = Math.min(...parentWidthArray)
    },
    checkNumber(value) {
      if (this.parentWidth < this.numberWidth) {
        return formatBigNumber(value)
      }
      return value
    },
  },
}
</script>

<style scoped>
.rbignumber--short-number {
  font-size: 3.0769rem;
  font-weight: 100;
  color: #3F3F3F;
}

.trending-up {
  color: var(--r-positive-color);
  font-size: 2rem !important;
}

.trending-flat {
  color: var(--r-neutral-color);
  font-size: 2rem !important;
}

.trending-down {
  color: var(--r-negative-color);
  font-size: 2rem !important;
}

.rbignumber--previous-period-value {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}
</style>
