<template>
  <div
    class="raccordionitem"
    :class="{ collapsed }"
  >
    <div class="d-flex align-center justify-space-between">
      <span
        v-if="title"
        class="title"
      >
        {{ title }}
      </span>
      <v-icon
        class="toggle-icon"
        @click="toggleCollapse"
      >
        {{ collapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
      </v-icon>
    </div>
    <div class="r-accordion-item--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RAccordionItem',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    itemIndex: {
      type: Number,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCollapse() {
      this.$emit('change', this.itemIndex)
    },
  },
}
</script>
