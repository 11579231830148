<template>
  <div class="rtopsentimentreviewcards">
    <r-tabs
      background-color="transparent"
      :items="tabs"
      :selected-tab="selectedTab"
      @change="onChangeTab"
    />

    <r-tab-items
      :key="tabKey"
      :selected-tab="selectedTab"
    >
      <slot>
        <r-tab-item
          v-for="item in tabItems"
          :key="item.label"
          :label="item.label"
        >
          <slot>
            <v-skeleton-loader
              v-if="loading"
              class=" mt-3"
              type="list-item-avatar-three-line"
              height="144"
            />
            <r-review-card
              v-if="!loading && item.content._id"
              :search="search"
              :highlighted-entities="appliedEntities"
              :avatar-url="item.content.avatarUrl"
              :username="item.content.username"
              :date="item.content.date"
              :url="item.content.url"
              :rating="item.content.rating"
              :title="item.content.title"
              :original-title="item.content.originalTitle"
              :review-id="item.content.reviewId"
              :sentiment="item.content.sentiment"
              :language="item.content.language"
              :text="item.content.text"
              :original-text="item.content.originalText"
              :entities="item.content.entities"
            />
            <r-no-results-message
              v-if="!loading && !item.content._id"
              class="ml-1 my-5"
            />
          </slot>
        </r-tab-item>
      </slot>
    </r-tab-items>

    <a
      class="rtopsentimentreviewcards--reviews ml-5"
      @click="onClickShowAllReviews"
    >
      {{ $t('reviewCard.seeAllReviews') }}
    </a>
  </div>
</template>

<script>
import RTabs from '@/components/library/molecules/RTabs'
import RTabItem from '@/components/library/molecules/RTabItem'
import RTabItems from '@/components/library/molecules/RTabItems'
import RReviewCard from '@/components/library/atoms/RReviewCard'
import RNoResultsMessage from '@/components/library/atoms/RNoResultsMessage'

export default {
  name: 'RTopSentimentReviewCards',
  components: {
    RReviewCard,
    RTabItem,
    RTabs,
    RTabItems,
    RNoResultsMessage,
  },
  props: {
    positiveReview: {
      type: Object,
      required: false,
    },
    negativeReview: {
      type: Object,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    appliedEntities: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [
        { label: this.$t('reviewCard.mostPositive'), key: 'Positive-tab' },
        { label: this.$t('reviewCard.mostNegative'), key: 'negative key' },
      ],
      selectedTab: 0,
    }
  },
  computed: {
    tabKey() {
      return `${this.positiveReview._id}-${this.negativeReview._id}`
    },
    tabItems() {
      return [
        {
          label: this.$t('reviewCard.mostPositive'),
          content: this.positiveReview,
        },
        {
          label: this.$t('reviewCard.mostNegative'),
          content: this.negativeReview,
        },
      ]
    },
  },
  methods: {
    onChangeTab(selectedTabIndex) {
      this.selectedTab = selectedTabIndex
    },
    onClickShowAllReviews() {
      this.$emit('click:see-all-reviews')
    },
  },
}
</script>

<style scoped>
.rtopsentimentreviewcards--reviews{
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1691CF
}
/deep/.v-tabs {
  border: none;
}
/deep/.v-tab {
  border: none;
  background-color: white;
  color: #3F3F3F;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
/deep/.v-tab--active {
  background-color: white;
  border: none !important;
  border-bottom: 1px solid #1691CF !important;
  color: #1691CF;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

</style>
