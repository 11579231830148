<template>
  <div class="rnetsentimenthalfdonut">
    <r-component-header v-if="title">
      {{ title }}
    </r-component-header>
    <div
      class="rnshd--content"
      :class="{ 'rnshd--content-horizontal': horizontal }"
    >
      <div :class="getMarginClasses">
        <table :class="{ 'ml-n4 mr-3 rnshd--border-spacing-horizontal': horizontal }">
          <tbody
            v-if="loading"
            class="rnshd--loader"
          >
            <v-skeleton-loader
              class="mt-1"
              type="chip"
            />
            <v-skeleton-loader
              class="mt-1"
              type="chip"
            />
            <v-skeleton-loader
              class="mt-1"
              type="chip"
            />
          </tbody>
          <tbody v-else>
            <tr
              class="rnshd--positive clickable"
              @click="onClick({ value: 'Positive' })"
            >
              <td>
                {{ $t('global.condition.positive') }}
              </td>
              <td>
                {{ numPositive }}
              </td>
              <td v-if="!horizontal">
                {{ $n(numPositivePercent, 'percent') }}
              </td>
            </tr>
            <tr
              class="rnshd--neutral clickable"
              @click="onClick({ value: 'Neutral' })"
            >
              <td>
                {{ $t('global.condition.neutral') }}
              </td>
              <td>
                {{ numNeutral }}
              </td>
              <td v-if="!horizontal">
                {{ $n(numNeutralPercent, 'percent') }}
              </td>
            </tr>
            <tr
              class="rnshd--negative clickable"
              @click="onClick({ value: 'Negative' })"
            >
              <td>
                {{ $t('global.condition.negative') }}
              </td>
              <td>
                {{ numNegative }}
              </td>
              <td v-if="!horizontal">
                {{ $n(numNegativePercent, 'percent') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="rnshd--chart mx-auto">
        <v-skeleton-loader
          v-if="loading"
          class="mt-16 rnshd--chart-loader"
          type="chip"
        />
        <highcharts
          v-else
          class="hc"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import getPercentage from '@/utils/getPercentage'
import i18n from '@/translations'

const SENTIMENT_MAPPING = {
  Negative: 'NEGATIVE',
  Positive: 'POSITIVE',
  Neutral: 'NEUTRAL',
}

export default {
  name: 'RNetSentimentHalfDonut',
  components: {
    RComponentHeader,
  },
  props: {
    numPositive: {
      type: Number,
      required: true,
    },
    numNeutral: {
      type: Number,
      required: true,
    },
    numNegative: {
      type: Number,
      required: true,
    },
    netSentiment: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numReviews() {
      return this.numPositive + this.numNegative + this.numNeutral
    },
    numPositivePercent() {
      return getPercentage({
        value: this.numPositive,
        total: this.numReviews,
      })
    },
    numNegativePercent() {
      return getPercentage({
        value: this.numNegative,
        total: this.numReviews,
      })
    },
    numNeutralPercent() {
      return getPercentage({
        value: this.numNeutral,
        total: this.numReviews,
      })
    },
    getMarginClasses() {
      return this.horizontal ? 'mt-5' : 'mt-2 mx-auto'
    },
    chartOptions() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: this.horizontal ? 236 : 300,
          width: this.horizontal ? 236 : 300,
        },
        title: {
          text: i18n.n(this.netSentiment, 'decimal'),
          align: 'center',
          verticalAlign: 'middle',
          y: this.horizontal ? 30 : 40,
          style: {
            fontSize: this.horizontal ? 18 : 22,
            color: this.netSentimentTextColor,
            fontWeight: 'bold',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '60%'],
            size: '110%',
            colors: ['var(--r-positive-color)', 'var(--r-neutral-color)', 'var(--r-negative-color)'],
          },
        },
        series: [{
          type: 'pie',
          innerSize: '50%',
          point: {
            events: {
              click: ({ point }) => this.onClick({ value: point.name }),
            },
          },
          data: [
            ['Positive', this.numPositivePercent],
            ['Neutral', this.numNeutralPercent],
            ['Negative', this.numNegativePercent],
          ],
        }],
      }
    },
    netSentimentTextColor() {
      if (this.netSentiment > 0.1 && this.netSentiment < 1) {
        return 'var(--r-positive-color)'
      }
      if (this.netSentiment > -0.1 && this.netSentiment < 0.1) {
        return 'var(--r-neutral-color)'
      }

      return 'var(--r-negative-color)'
    },
  },
  methods: {
    onClick({ value }) {
      this.$emit('click:sentiment', { value: SENTIMENT_MAPPING[value] })
    },
  },
}
</script>

<style scoped>
.rnshd--content {
  display: flex;
  flex-direction: column;
}

.rnshd--content-horizontal {
  flex-direction: row;
}

.rnshd--border-spacing-horizontal {
  border-spacing: 16px 5px;
}

table {
  border-spacing: 16px 10px;
  font-size: 13px;
}

td:nth-child(2) {
  font-size: 22px;
  font-weight: 300;
}

.rnshd--positive {
  color: var(--r-positive-color);
}

.rnshd--neutral {
  color: var(--r-neutral-color);
}

.rnshd--negative {
  color: var(--r-negative-color);
}
</style>
