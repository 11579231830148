<template>
  <v-row class="rsitesanalyzed">
    <v-col md="8">
      <r-sites-analyzed-table
        :data="data"
        :loading="loading"
        @click:hostname="onClickHostname"
      />
    </v-col>
    <v-col md="4">
      <r-pie-chart
        v-if="data.length > 1"
        :data="pieChartData"
        :loading="loading"
        :height="350"
        :width="350"
      />
    </v-col>
  </v-row>
</template>

<script>
import RSitesAnalyzedTable from '@/components/library/atoms/RSitesAnalyzedTable'
import RPieChart from '@/components/library/atoms/RPieChart'

export default {
  name: 'RSitesAnalyzed',
  components: {
    RSitesAnalyzedTable,
    RPieChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pieChartData() {
      return this.$props.data.map(({ hostname, numReviews }) => ({
        name: hostname,
        value: numReviews,
      }))
    },
  },
  methods: {
    onClickHostname(value) {
      this.$emit('click:hostname', { value })
    },
  },
}
</script>

<style scoped>
  .rsitesanalyzed {
    align-items: start;
  }
  .rsitesanalyzed /deep/ .rpiechart {
    margin-left: 40px !important;
  }
</style>
