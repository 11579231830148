<template>
  <div class="rsbch">
    <r-component-header v-if="title">
      {{ title }}
    </r-component-header>
    <v-skeleton-loader
      v-if="loading"
      class="pa-1"
      type="image"
      width="100%"
      height="400"
    />
    <div v-else-if="(data.length > 0) && !loading">
      <highcharts
        class="rsbch--chart"
        :options="chartOptions"
      />
    </div>
    <div v-else>
      <p class="rsbch--no-data">
        {{ $t('global.messages.noData') }}
      </p>
    </div>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import didNotClickOn from '@/utils/didNotClickOn'
import { COMPARISON_CHART_COLORS } from '../../../utils/constants'

const SENTIMENT_MAPPING = {
  Negative: 'NEGATIVE',
  Positive: 'POSITIVE',
  Neutral: 'NEUTRAL',
}

export default {
  name: 'RSentimentBarChart',
  components: {
    RComponentHeader,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    breakdown: {
      type: String,
      default: 'sentiment',
    },
    legendEnabled: {
      type: Boolean,
      default: false,
    },
    tooltipEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    categories() {
      return this.data.map((item) => item.label)
    },
    series() {
      const isSentiment = this.breakdown === 'sentiment'
      const initialData = isSentiment ? {
        negative: [],
        neutral: [],
        positive: [],
      } : {
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
      }

      const breakdownData = this.data.reduce((acc, item) => {
        Object.entries(item.value).forEach((sentimentKey) => {
          acc[sentimentKey[0]].push({ y: sentimentKey[1], item })
        })

        return acc
      }, initialData)

      const breakdownKeys = Object.keys(breakdownData)
      const colors = [...COMPARISON_CHART_COLORS[isSentiment ? 'SENTIMENT' : 'RATING_DISTRIBUTION']]

      if (isSentiment) {
        colors.reverse()
      }

      const series = breakdownKeys.map((key, i) => ({
        name: this.$t(`global.keys.${key.toLowerCase()}`),
        color: colors[i],
        data: breakdownData[key],
        legendIndex: breakdownKeys.length - i,
      }))

      return series
    },
    chartOptions() {
      const ctx = this

      return {
        chart: {
          type: 'bar',
          borderWidth: 0,
          showAxes: false,
          width: this.width,
          style: {
            overflow: 'visible',
          },
        },
        xAxis: {
          categories: this.categories,
          title: {
            text: null,
          },
          gridLineWidth: 0,
          lineWidth: 0,
          labels: {
            enabled: true,
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          gridLineWidth: 0,
          visible: true,
          stackLabels: {
            style: {
              color: 'black',
            },
            enabled: true,
            overflow: 'allow',
            crop: false,
          },
          labels: {
            enabled: false,
          },
        },
        legend: {
          enabled: this.legendEnabled,
          itemMarginTop: 10,
          itemMarginBottom: 40,
          symbolHeight: 8,
          symbolWidth: 8,
          symbolPadding: 10,
          itemStyle: {
            lineHeight: '20px',
            fontWeight: '400',
            color: 'rgba(63, 63, 63, 0.75)',
          },
        },
        tooltip: {
          enabled: this.tooltipEnabled,
          headerFormat: '',
          pointFormat: '{point.y}',
          formatter() {
            const { series: thisSeries, point } = this
            const { name: category } = thisSeries

            return `<span><b>${category}</b>: ${point.y}</span>`
          },
        },
        plotOptions: {
          bar: {
            stacking: 'percent',
            groupPadding: 0.15,
            pointPadding: 0.001,
            dataLabels: {
              enabled: false,
            },
          },
          series: {
            cursor: 'pointer',
            events: {
              click(e) {
                const { point } = e
                const { item } = point.options

                const clickedSentiment = point.series.name
                const clickedCategory = point.category

                ctx.onClickBar({ clickedSentiment, clickedCategory, item })
              },
            },
          },
        },
        series: this.series,
      }
    },
  },
  beforeDestroy() {
    this.$el.removeEventListener('click', this.onClickEl)
  },
  mounted() {
    this.$el.addEventListener('click', this.onClickEl)
  },
  methods: {
    onClickBar({ clickedSentiment, clickedCategory: entity, item }) {
      const entitySentiment = SENTIMENT_MAPPING[clickedSentiment]
      const { entityGroupId } = item

      const value = {
        label: entity,
        entitySentiment,
        entityGroupId,
      }

      this.$emit('click:entity', { value })
    },
    onClickEl(e) {
      if (didNotClickOn(e.target, {
        classes: [
          'highcharts-xaxis-labels',
          'highcharts-stack-labels',
        ],
      })) {
        return
      }
      let entity
      if (didNotClickOn(e.target, { classes: ['highcharts-stack-labels'] })) {
        entity = e.target.textContent
      } else {
        const stackLabelNode = e.target.parentNode
        const stackLabelIndex = [...stackLabelNode.parentNode.children].indexOf(stackLabelNode)
        entity = this.categories[stackLabelIndex]
      }

      const { entitySentiment, entityGroupId } = this.data.find((item) => item.label === entity)
      const value = {
        label: entity,
        entitySentiment,
        entityGroupId,
      }

      this.$emit('click:entity', { value })
    },
  },
}
</script>

<style>
.rsbch--no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.rsbch .highcharts-xaxis-labels text,
.rsbch .highcharts-stack-labels text {
  cursor: pointer !important;
}
</style>
