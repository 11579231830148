<template>
  <div data-testid="rbarchart">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </div>
</template>

<script>

import chartKeyToColor from '@/utils/chartKeyToColor'

export default {
  name: 'RBarChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 150,
    },
    width: {
      type: Number,
      default: 150,
    },
  },
  computed: {
    chartOptions() {
      const axisConfig = {
        visible: false,
      }

      return {
        chart: {
          type: this.vertical ? 'column' : 'bar',
          height: this.height,
          width: this.width,
        },
        series: [{
          showInLegend: false,
          innerSize: '55%',
          data: this.formatData(this.data),
        }],
        yAxis: axisConfig,
        xAxis: axisConfig,
      }
    },
  },
  methods: {
    convertValueToPercentage(total, value) {
      return ((value * 100) / total)
    },
    formatData(data) {
      if (!data) {
        return []
      }

      return data.map((item) => ({
        name: item.name,
        color: chartKeyToColor(item.name),
        y: item.value,
      }))
    },
  },
}
</script>

<style scoped>
</style>
