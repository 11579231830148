<template>
  <div class="rtextchips">
    <r-chip
      v-for="(chip, i) in chips"
      :key="`rtextchips-${i}`"
      class="mr-2 my-1 d-inline-block"
      :label="chip"
      :width="66"
      removable
      @click="$emit('remove-chip', { value: chip })"
    />
    <v-text-field
      v-model="newChip"
      :disabled="disabled"
      solo
      dense
      flat
      :placeholder="placeholder"
      class="pa-0 ma-0"
      @keyup.native="onKeyup"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'

const ADD_CHIP_ON_KEYPRESS = [
  'Enter',
  ',',
]

export default {
  name: 'RTextChips',
  components: {
    RChip,
  },
  props: {
    chips: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Add item',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    newChip: '',
  }),
  methods: {
    addChip() {
      this.newChip = this.newChip.replace(',', '')
      if (!this.newChip.length) {
        return
      }
      this.$emit('add-chip', { value: this.newChip })
      this.newChip = ''
    },
    onKeyup(e) {
      if (ADD_CHIP_ON_KEYPRESS.indexOf(e.key) === -1) {
        return
      }
      this.addChip()
    },
    onBlur() {
      this.addChip()
    },
  },
}
</script>

<style scoped>
/deep/ .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control > .v-input__slot {
  padding-left: 4px;
}
/deep/ .v-text-field input {
  display: inline;
  padding-left: 0;
}
/deep/ .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot,
/deep/ .v-text-field > .v-input__control > .v-input__slot,
/deep/ .v-text-field .v-input__control {
  display: inline;
}
/deep/ .v-text-field__details {
  display: none;
}
.b-text-chips {
  border: 1px solid rgba(0, 0, 0, 0.24);
  overflow: auto;
}
/deep/ .v-input__slot {
  padding-right: 0 !important;
}
</style>
