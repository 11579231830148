<template>
  <div class="rpiechart">
    <div
      v-if="loading"
    >
      <v-skeleton-loader
        class="rpiechart--loader rounded-circle elevation-0"
        type="image"
        :height="height"
        :width="width"
      />
    </div>

    <highcharts
      v-else
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </div>
</template>

<script>

import { getColorForNumber } from '@/utils/constants'

export default {
  name: 'RPieChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 150,
    },
    width: {
      type: Number,
      default: 150,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'pie',
          events: {
            load() {
              this.series[0].points.forEach((point) => {
                let distance = 0
                if (point.y <= 5) {
                  distance = '30%'
                } else {
                  distance = '-30%'
                }
                point.update({
                  dataLabels: {
                    distance,
                  },
                })
              })
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: 'white',
          borderWidth: 1,
          shadow: false,
          formatter() {
            return `<b>${this.point.name}: ${(this.point.y.toFixed(2))}%</b>`
          },
        },
        plotOptions: {
          pie: {
            size: 350,
          },
        },
        series: [{
          showInLegend: false,
          dataLabels: {
            enabled: true,
          },
          data: this.formatData(this.data),
        }],
      }
    },
  },
  methods: {
    convertValueToPercentage(total, value) {
      return ((value * 100) / total)
    },
    formatData(data) {
      if (!data) {
        return []
      }

      const total = data.reduce((sum, item) => sum + item.value, 0)

      return data.map((item, i) => ({
        name: item.name,
        color: getColorForNumber(i),
        y: this.convertValueToPercentage(total, item.value),
      }))
    },
  },
}
</script>

<style scoped>
</style>
