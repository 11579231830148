const positiveRgb = [76, 161, 90]
const neutralRgb = [187, 185, 192]
const negativeRgb = [215, 74, 74]

const formatRgbAsString = (rgbArr) => `rgb(${rgbArr[0]}, ${rgbArr[1]}, ${rgbArr[2]})`

const rgbBetween = (a, b, scale) => {
  const rgbDelta = [
    (b[0] - a[0]) * scale,
    (b[1] - a[1]) * scale,
    (b[2] - a[2]) * scale,
  ]
  return [
    a[0] + rgbDelta[0],
    a[1] + rgbDelta[1],
    a[2] + rgbDelta[2],
  ]
}

export default (sentimentScore) => {
  if (sentimentScore === 0) {
    return formatRgbAsString(neutralRgb)
  }
  if (sentimentScore > 0) {
    return formatRgbAsString(rgbBetween(neutralRgb, positiveRgb, sentimentScore))
  }
  return formatRgbAsString(rgbBetween(neutralRgb, negativeRgb, 0 - sentimentScore))
}
