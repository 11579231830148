<template>
  <v-skeleton-loader
    v-if="loading"
    class="elevation-0 rsatable--loader"
    type="table-thead, table-tbody"
    height="322"
  />

  <table
    v-else
    class="rsatable"
  >
    <thead class="rsatable--header">
      <tr>
        <th>{{ $t('sitesAnalyzedTable.siteAnalysis') }}</th>
        <th>{{ $t('sitesAnalyzedTable.reviews') }}</th>
        <th>{{ $t('sitesAnalyzedTable.netSentiment') }}</th>
        <th>{{ $t('sitesAnalyzedTable.averageRating') }}</th>
      </tr>
    </thead>

    <tbody class="rsatable--body">
      <tr
        v-for="(entry, i) in data"
        :key="entry.hostname"
        class="clickable"
        @click="onClick(entry.hostname)"
      >
        <td class="rsatable--hostname d-flex">
          <span
            class="color-circle mr-2"
            :style="{
              backgroundColor: getColorForNumber(i)
            }"
          />
          <span class="hostname">
            {{ entry.hostname }}
          </span>
        </td>
        <td>{{ entry.numReviews }}</td>
        <td
          class="rsatable--net-sentiment"
          :style="{
            color: getSentimentColor(entry.netSentiment),
          }"
        >
          {{ formatNumber(entry.netSentiment) }}
        </td>
        <td>
          <v-icon class="rsatable--star">
            mdi-star
          </v-icon>
          {{ formatNumber(entry.averageRating) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { getColorForSentiment, getColorForNumber } from '@/utils/constants'

export default {
  name: 'RSitesAnalyzedTable',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      required: false,
      validator: (values) => {
        for (let i = 0; i < values.length; i += 1) {
          const {
            hostname,
            numReviews,
            netSentiment,
            averageRating,
          } = values[i]

          if (
            !(
              hostname
              && typeof hostname === 'string'
              && typeof numReviews === 'number'
              && typeof netSentiment === 'number'
              && typeof averageRating === 'number'
            )
          ) return false
        }
        return true
      },
    },
  },
  methods: {
    getColorForNumber,
    formatNumber(num) {
      return num ? num.toFixed(2) : 0
    },
    getSentimentColor(sentiment) {
      return getColorForSentiment(sentiment)
    },
    onClick(value) {
      this.$emit('click:hostname', { value })
    },
  },
}
</script>

<style scoped>
.rsatable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.rsatable--loader {
  border: 1px solid var(--r-border-color);
}
.rsatable--header {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #3F3F3F;
  text-align: left;
  border-bottom: 1px solid var(--r-border-color);
}
.rsatable--header th {
  padding: 8px;
}
.rsatable--body::before {
  content: "";
  display: block;
  color: transparent;
  margin-top: 16px;
}
.rsatable--body td {
  width: 25%;
  padding: 12px 8px;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}
.rsatable--hostname {
  align-items: center;
}
.color-circle {
  height: 15px;
  min-width: 15px;
  border-radius: 50%;
}
.rsatable--net-sentiment {
  font-weight: 700;
}
.rsatable--star {
  padding-bottom: 3px;
  margin-right: 5px;
  color: #FBC02D;
}
</style>
