<template>
  <div
    class="rsrdc mb-5"
  >
    <r-component-header v-if="title">
      {{ title }}
    </r-component-header>
    <v-row
      v-for="({ label: ratingValue, value: percentageValue }) of ratings"
      :key="`rsrdc-row-${ratingValue}`"
      class="mt-0"
    >
      <v-col
        class="rsrdc--rating clickable"
        @click="() => onClickRatingStar({ value: ratingValue })"
      >
        <r-rating
          :value="ratingValue"
        />
      </v-col>
      <v-col class="rsrdc--progress-bar">
        <v-skeleton-loader
          v-if="loading"
          type="list-item"
        />
        <v-progress-linear
          v-else
          class="mt-1 clickable"
          :value="percentageValue * 100"
          buffer-value="100"
          height="12"
          background-color="var(--r-light-grey)"
          color="var(--r-positive-color)"
          @click="() => onClickRatingStar({ value: ratingValue })"
        />
      </v-col>
      <v-col class="rsrdc--percentage-text">
        <span v-if="!loading">{{ $n(percentageValue, 'percent') }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader.vue'
import RRating from '@/components/library/atoms/RRating'

export default {
  name: 'RStarRatingDistributionChart',
  components: {
    RComponentHeader,
    RRating,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ratings: [],
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(dataValue) {
        this.ratings = dataValue
          .map(({ label, value }) => ({ label: parseInt(label, 10), value }))
          .sort((a, b) => a.label > b.label)
      },
    },
  },
  methods: {
    onClickRatingStar({ value }) {
      this.$emit('click:rating', { value })
    },
  },
}
</script>

<style scoped>
.rsrdc--rating {
  max-width: 95px;
  min-width: 95px;
}

.rsrdc--progress-bar {
  max-width: 100%;
}

.rsrdc--percentage-text {
  max-width: 50px;
  font-size: 12px;
  text-align: right;
}

/deep/ .v-skeleton-loader__list-item {
  height: 23px;
}
</style>
