<template>
  <div class="raveragestarrating">
    <r-component-header>{{ title }}</r-component-header>
    <div class="d-flex align-center mt-4">
      <v-skeleton-loader
        v-if="loading"
        type="chip"
        class="raveragestarrating--number-loader"
      />
      <div
        v-else
        class="raveragestarrating--number-value"
      >
        {{ ratingValue }}
      </div>
      <v-divider
        class="divider-line"
        vertical
      />
      <div class="raveragestarrating--fixed-number-value">
        5
      </div>
    </div>
    <r-rating
      v-if="!loading"
      class="mb-2 raveragestarrating--rating"
      :value="ratingValue"
      :is-half-incrementing="isHalfIncrementing"
      :readonly="false"
      @change="(payload) => this.$emit('click:rating', payload)"
    />
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RRating from '@/components/library/atoms/RRating'

export default {
  name: 'RAverageStarRating',
  components: {
    RComponentHeader,
    RRating,
  },
  props: {
    value: {
      type: Number,
      required: true,
      validator: (value) => (value >= 0 && value <= 5),
    },
    title: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isHalfIncrementing: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ratingValue() {
      return parseFloat(this.value.toFixed(1))
    },
  },
}
</script>

<style scoped>
.raveragestarrating--number-value {
  font-size: 3.0769rem;
  font-weight: 100;
  color: #3F3F3F;
}

.raveragestarrating--fixed-number-value {
  font-size: 1.6923rem;
  font-weight: 100;
}

.raveragestarrating--rating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 4px;
}
</style>
