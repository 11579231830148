<template>
  <div
    class="rdaterangepicker"
    :style="zIndexStyle"
  >
    <div class="rdaterangepicker--box">
      <date-picker
        ref="rdaterangepickerRef"
        v-model="dateRange"
        :open="false"
        inline
        type="date"
        range
        @change="handleDateChange"
      >
        <template v-slot:header>
          <div class="rdaterangepicker--header-quick-actions">
            <div class="rdaterangepicker--header-quick-actions-title">
              {{ $t('rdateRange.quickSelect') }}
            </div>
            <div class="rdaterangepicker--header-quick-actions-buttons-container">
              <button
                v-for="(action, index) in quickSelections"
                :key="action.label"
                :class="{ active: action.isActive}"
                class="rdaterangepicker--header-quick-actions-buttons"
                @click="() => onClickQuickAction(action.value, index)"
              >
                {{ action.label }}
              </button>
            </div>
          </div>
          <div class="d-flex pt-5">
            <div class="rdaterangepicker--header-start-end">
              <div>{{ $t('global.start') }}</div>
              <input
                ref="inputValueFromRef"
                v-model="inputValueFrom"
                :max="inputValueTo"
                class="rdaterangepicker--header-input"
                name="start"
                type="date"
              >
            </div>
            <div class="rdaterangepicker--header-start-end rdaterangepicker--header-end">
              <div>{{ $t('global.end') }}</div>
              <input
                ref="inputValueToRef"
                v-model="inputValueTo"
                class="rdaterangepicker--header-input"
                name="end"
                type="date"
              >
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="rdaterangepicker--footer">
            <button
              class="rdaterangepicker--footer-button cancel"
              @click="handleCancel"
            >
              {{ $t('global.actions.cancel') }}
            </button>
            <button
              :disabled="!isValid"
              class="rdaterangepicker--footer-button apply"
              @click="handleApplyClick"
            >
              {{ $t('global.actions.apply') }}
            </button>
          </div>
        </template>
      </date-picker>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'RDateRangePicker',
  components: { DatePicker },
  props: {
    quickSelections: {
      type: Array,
      default() {
        return [
          {
            label: this.$i18n.t('global.calendar.today'),
            value: {
              startDate: this.$moment().format('YYYY-MM-DD'),
              endDate: this.$moment().format('YYYY-MM-DD'),
            },
            isActive: true,
          },
          {
            label: this.$i18n.tc('global.calendar.day', 7),
            value: {
              startDate: this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
              endDate: this.$moment().format('YYYY-MM-DD'),
            },
            isActive: false,
          },
          {
            label: this.$i18n.tc('global.calendar.day', 14),
            value: {
              startDate: this.$moment().subtract(13, 'days').format('YYYY-MM-DD'),
              endDate: this.$moment().format('YYYY-MM-DD'),
            },
            isActive: false,
          },
          {
            label: this.$i18n.tc('global.calendar.day', 30),
            value: {
              startDate: this.$moment().subtract(29, 'days').format('YYYY-MM-DD'),
              endDate: this.$moment().format('YYYY-MM-DD'),
            },
            isActive: false,
          },
        ]
      },
    },
    activeQuickAction: {
      type: Number,
      default: undefined,
    },
    range: {
      type: Array,
      default() { return [this.$moment().subtract(6, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')] },
    },
    zIndex: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    dateRange: [],
    inputValueFrom: '',
    inputValueTo: '',
    isValid: true,
  }),
  computed: {
    zIndexStyle() {
      return {
        'z-index': this.zIndex,
      }
    },
  },
  watch: {
    inputValueFrom(newV, oldV) {
      if (newV !== oldV && newV !== this.dateRange[0] && (new Date(newV)).toString() !== 'Invalid Date') {
        if (new Date(newV) > new Date(this.inputValueTo)) {
          this.isValid = false
          this.$refs.inputValueFromRef.classList.add('errorDateInput')
        } else {
          this.isValid = true
          this.$refs.inputValueFromRef.classList.remove('errorDateInput')
          const dateFrom = new Date(newV)
          const dateTo = new Date(this.inputValueTo)
          const userTimezoneOffsetFrom = dateFrom.getTimezoneOffset() * 60000
          const userTimezoneOffsetTo = dateTo.getTimezoneOffset() * 60000
          this.dateRange = [new Date(dateFrom.getTime() + userTimezoneOffsetFrom),
            new Date(dateTo.getTime() + userTimezoneOffsetTo)]
        }
      }
    },
    inputValueTo(newV, oldV) {
      if (newV !== oldV && newV !== this.dateRange[1] && (new Date(newV)).toString() !== 'Invalid Date') {
        if (new Date(newV) < new Date(this.inputValueFrom)) {
          this.isValid = false
          this.$refs.inputValueToRef.classList.add('errorDateInput')
        } else {
          this.isValid = true
          this.$refs.inputValueToRef.classList.remove('errorDateInput')
          this.isValid = true
          this.$refs.inputValueFromRef.classList.remove('errorDateInput')
          const dateFrom = new Date(this.inputValueFrom)
          const dateTo = new Date(newV)
          const userTimezoneOffsetFrom = dateFrom.getTimezoneOffset() * 60000
          const userTimezoneOffsetTo = dateTo.getTimezoneOffset() * 60000
          this.dateRange = [new Date(dateFrom.getTime() + userTimezoneOffsetFrom),
            new Date(dateTo.getTime() + userTimezoneOffsetTo)]
        }
      }
    },
    activeQuickAction() {
      this.setActiveAction()
    },
  },

  beforeMount() {
    this.setActiveAction()
  },
  mounted() {
    const [startDate, endDate] = this.range
    const startDateMoment = this.$moment(startDate)
    const endDateMoment = this.$moment(endDate)
    this.inputValueFrom = startDateMoment.format('YYYY-MM-DD')
    this.inputValueTo = endDateMoment.format('YYYY-MM-DD')
    this.dateRange = [new Date(startDate), new Date(endDateMoment)]
  },
  methods: {
    handleApplyClick() {
      const value = { startDate: this.inputValueFrom, endDate: this.inputValueTo }
      this.$emit('change', { value })
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleDateChange(input) {
      this.inputValueFrom = this.$moment(input[0]).format('YYYY-MM-DD')
      this.inputValueTo = this.$moment(input[1]).format('YYYY-MM-DD')
    },
    onClickQuickAction({ startDate, endDate }, index) {
      this.dateRange = [new Date(startDate), new Date(endDate)]
      this.inputValueFrom = startDate
      this.inputValueTo = endDate
      this.quickSelections.forEach((action, ind) => {
        // eslint-disable-next-line no-param-reassign
        action.isActive = ind === index
      })
    },
    setActiveAction() {
      this.quickSelections.forEach((action) => {
        const { startDate, endDate } = action.value
        // eslint-disable-next-line no-param-reassign
        action.isActive = this.$moment(endDate).diff(startDate, 'days') === this.activeQuickAction
      })
    },
  },
}
</script>

<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/deep/.mx-datepicker-main {
  border: none!important;
  background-color: unset;
}
/deep/.mx-datepicker-header {
  border: none!important;
  padding: 0 !important;
}
/deep/.rdaterangepicker--header-start-end {
  width: 50%;
  padding: 6px 12px;
  color: #3F3F3F;
  font-size: 13px;
}
/deep/.rdaterangepicker--header-end {
  border-left: 1px solid rgba(63, 63, 63, 0.2) !important;
}

/deep/.mx-calendar+.mx-calendar {
  border-left: 1px solid rgba(63, 63, 63, 0.2) !important;
}
/deep/.mx-calendar-header, /deep/.mx-table thead {
  background-color: #fafafa;
}
/deep/.mx-calendar-header {
  padding: 0 75px;
}
/deep/.mx-calendar-header button i:before {
  border-color: rgba(63, 63, 63, 0.75);
  border-width: 3px 0 0 3px;
}
/deep/.mx-table thead::after {
  content: " ";
  height: 1px;
  width: 100%;
  background-color: #dedede;
  position: absolute;
  left: 0;
}
.rdaterangepicker--header-input {
  border: 1px solid rgba(63, 63, 63, 0.2);
  background: #FFFFFF;
  width: 100%;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none
}
.rdaterangepicker--header-quick-actions {
  border-bottom:  1px solid rgba(63, 63, 63, 0.2);
  display: flex;
  align-items: flex-start;
  padding: 8px 16px 8px 31px;
}
.rdaterangepicker--header-quick-actions-title {
  white-space: nowrap;
  color: rgba(63, 63, 63, 0.75);
  font-size: 12px;
  line-height: 20px;
  margin-right: 15px;
}
.rdaterangepicker--header-quick-actions-buttons-container {
  text-align: right;
}
.rdaterangepicker--header-quick-actions-buttons {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1691CF;
  margin-left: 16px;
}
.rdaterangepicker--header-quick-actions-buttons:first-child {
  margin-left: 0;
}
.rdaterangepicker--box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 603px;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 63, 0.2);
  border-radius: 5px;
}
/deep/.mx-calendar-content .cell.active {
  background: var(--primary-color) !important;
  border-radius: 50%;
  color: #F8F8F8 !important;
}
.rdaterangepicker--footer-button{
  padding: 4px 12px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
}
.rdaterangepicker--footer {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.rdaterangepicker--footer-button.cancel{
  margin-right: 8px;
  color: var(--primary-color);
}
.rdaterangepicker--footer-button.apply{
  background: var(--primary-color);
  color: #F8F8F8;
  border: none;
}
/deep/ .mx-datepicker-footer {
  border-top: 1px solid rgba(63, 63, 63, 0.2) !important;
  border-radius: 0 0 5px 5px !important;
  padding: unset;
}
/deep/ .mx-range-wrapper {
  padding-bottom: 16px;
}
/deep/ .mx-range-wrapper .mx-calendar.mx-calendar-panel-date {
  width: 50%;
}
/deep/ .mx-calendar-content .cell {
  max-height: 32px;
}
/deep/ .mx-calendar-content .cell.active {
  border-radius: unset;
  color: #F8F8F8 !important;
  background-color: #dbedfb !important;
}

/deep/ .mx-calendar-content .cell.active div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  max-width: 32px;
  height: 32px;
  background: var(--primary-color) !important;
  border-radius: 50%;
  color: #F8F8F8 !important;
  margin: 0 auto;
}
/deep/ .mx-btn-icon-double-right,/deep/.mx-btn-icon-double-left {
  display: none !important;
}

.rdaterangepicker {
  position: absolute;
}
.errorDateInput {
  border: 1px solid rgb(255, 0, 0, 0.6);
}
.active{
  color: black;
}
</style>
