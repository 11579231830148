var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rreviewcardlist"
  }, [_vm.loading ? _c('div', {
    staticClass: "rreviewcardlist--skeleton"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card-heading"
    }
  }), _c('v-row', {
    staticClass: "ma-0 pa-0"
  }, _vm._l(_vm.pageSize, function (i) {
    return _c('v-col', {
      key: `loader-${i}`,
      staticClass: "rreviewcard--skeleton pa-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider', {
      staticClass: "dotted ma-0"
    }), _c('v-skeleton-loader', {
      staticClass: "mr-6 pa-0",
      attrs: {
        "type": "list-item-avatar-two-line, list-item-three-line"
      }
    })], 1);
  }), 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "rreviewcardlist--content"
  }, [_c('div', {
    staticClass: "py-3 px-6 d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "rreviewcardlist--content-close",
    on: {
      "click": _vm.onClickClose
    }
  }, [_vm._v(" close ")]), _c('p', {
    staticClass: "black--text rreviewcardlist--title mb-0"
  }, [_vm._v(" " + _vm._s(_vm.reviewsInShortNotion) + " " + _vm._s(_vm.$t('resources.reviews')) + " ")])], 1), _vm.showSortMenu ? _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "tile": "",
      "content-class": "elevation-1 sectionborder"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('a', _vm._g(_vm._b({
          attrs: {
            "id": "dropdownmenu"
          }
        }, 'a', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t('reviewListing.sortBy')) + " ")])];
      }
    }], null, false, 2889740823),
    model: {
      value: _vm.isSortDropdownOpen,
      callback: function ($$v) {
        _vm.isSortDropdownOpen = $$v;
      },
      expression: "isSortDropdownOpen"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "300",
      "tile": ""
    }
  }, [_c('v-list', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item-group', {
    staticClass: "mt-0",
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selectedSort,
      callback: function ($$v) {
        _vm.selectedSort = $$v;
      },
      expression: "selectedSort"
    }
  }, _vm._l(_vm.sortOptions, function (item, i) {
    return _c('v-list-item', {
      key: i,
      attrs: {
        "disabled": _vm.selectedSort === i
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.label)
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e()], 1), _vm._l(_vm.reviews, function (review) {
    return _c('v-row', {
      key: review._id,
      staticClass: "ma-0 pa-0 rreviewcardlist--item"
    }, [_c('v-col', {
      staticClass: "pa-0"
    }, [_c('v-divider', {
      staticClass: "dotted ma-0"
    }), _c('r-review-card', {
      key: review.reviewId,
      attrs: {
        "review-id": review.reviewId,
        "search": _vm.search,
        "highlighted-entities": _vm.appliedEntities,
        "avatar-url": review.avatarUrl,
        "username": review.username,
        "date": review.date,
        "url": review.url,
        "rating": review.rating,
        "title": review.title,
        "original-title": review.originalTitle,
        "sentiment": review.sentiment,
        "language": review.language,
        "text": review.text,
        "original-text": review.originalText,
        "entities": review.entities
      }
    })], 1)], 1);
  }), _vm.showNoResultMessage ? _c('v-row', {
    staticClass: "ma-0 pa-0 rreviewcardlist--noresults"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('v-divider', {
    staticClass: "dotted ma-0"
  }), _c('r-no-results-message')], 1)], 1) : _vm._e()], 2) : _vm._e(), _vm.showPagination ? _c('r-pagination', {
    staticClass: "py-5 pa-0",
    attrs: {
      "page": _vm.page,
      "total-pages": _vm.totalPages,
      "justify": "center"
    },
    on: {
      "click:page": _vm.onPageChange
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }