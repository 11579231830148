var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rmap"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "pa-1",
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "500"
    }
  }) : _c('highcharts', {
    attrs: {
      "constructor-type": 'mapChart',
      "options": _vm.mapOptions
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }