<template>
  <div class="rtrends">
    <div class="d-flex">
      <r-component-header class="mt-1 mr-4">
        {{ $t('rte.trends') }}
      </r-component-header>
      <r-select
        :allow-empty="false"
        :items="items"
        :show-search="false"
        class="flex-grow-1 font-weight-regular"
        width="100%"
        @change="emitInterValChange"
      />
    </div>
    <div v-if="loading">
      <v-skeleton-loader
        class="rtrends--mt elevation-0 dataalertscards--loader"
        type="image"
        width="100%"
        height="50"
      />

      <v-skeleton-loader
        class="rtrends--mt elevation-0 dataalertscards--loader"
        type="image"
        width="100%"
        height="50"
      />
    </div>

    <template v-else>
      <div class="rtrends--mt">
        <div
          class="rtrends--chart-container"
          @click="onClickContainerArea({ type: TYPES.POSITIVE })"
        >
          <div class="d-flex justify-center align-center">
            <div>
              <p class="rtrends--positive-text ma-0">
                {{ $t('global.condition.positive') }}
              </p>
            </div>
            <div class="ml-4">
              <p class="rtrends--positive-value ma-0">
                {{ totalPositive }}
              </p>
            </div>
          </div>
          <div class="rtrends--highcharts-wrapper">
            <highcharts
              ref="chart-positive"
              :options="chartOptionsPositive"
              style="width:100%"
            />
          </div>
          <div class="d-flex justify-center align-center">
            <div>
              <img
                v-if="positivePercentIncrease >= 0"
                src="@/assets/images/trendingEntities/green-arrow-up.svg"
                alt="arrow"
              >
              <img
                v-else
                src="@/assets/images/trendingEntities/green-arrow-down.svg"
                alt="arrow"
              >
            </div>
            <div class="ml-4">
              <p class="rtrends--positive-percent">
                {{ Math.floor(positivePercentIncrease) }}%
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center align-center mt-2">
          <div v-if="positiveEntities.length > 0">
            <img
              class="rtrends--fire-icons"
              src="@/assets/images/trendingEntities/fire.svg"
              alt="fire"
              @click="onClickContainerArea({ type: TYPES.POSITIVE })"
            >
          </div>
          <div>
            <p class="rtrends--fire-positive">
              <span
                v-for="(entity, index) in positiveEntities"
                :key="index"
                @click="onClickEntity({ type: TYPES.POSITIVE, entity })"
              >
                {{ entity }}{{ index !== positiveEntities.length - 1 ? ';' : '' }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="rtrends--mt">
        <div
          class="rtrends--chart-container"
          @click="onClickContainerArea({ type: TYPES.NEGATIVE })"
        >
          <div class="d-flex justify-center align-center">
            <div>
              <p class="rtrends--negative-text ma-0">
                {{ $t('global.condition.negative') }}
              </p>
            </div>
            <div class="ml-4">
              <p class="rtrends--negative-value ma-0">
                {{ totalNegative }}
              </p>
            </div>
          </div>
          <div class="rtrends--highcharts-wrapper">
            <highcharts
              ref="chart-negative"
              :options="chartOptionsNegative"
            />
          </div>
          <div class="d-flex justify-center align-center">
            <div>
              <img
                v-if="negativePercentIncrease > 0"
                src="@/assets/images/trendingEntities/red-arrow-up.svg"
                alt="arrow"
              >
              <img
                v-else
                src="@/assets/images/trendingEntities/red-arrow-down.svg"
                alt="arrow"
              >
            </div>
            <div class="ml-4">
              <p class="rtrends--negative-percent">
                {{ Math.floor(negativePercentIncrease) }}%
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center align-center mt-2">
          <div v-if="negativeEntities.length > 0">
            <img
              class="rtrends--fire-icons"
              src="@/assets/images/trendingEntities/red-fire.svg"
              alt="arrow"
              @click="onClickContainerArea({ type: TYPES.NEGATIVE })"
            >
          </div>
          <div>
            <p class="rtrends--fire-negative">
              <span
                v-for="(entity, index) in negativeEntities"
                :key="index"
                @click="onClickEntity({ type: TYPES.NEGATIVE, entity })"
              >
                {{ entity }}{{ index !== negativeEntities.length - 1 ? ';' : '' }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RSelect from '@/components/library/molecules/RSelect'
import dateStrToDate from '@/utils/dateStrToDate'

const dataToSeriesMap = (item) => [dateStrToDate(item.date), item.value]
const getCommonConfigOfChart = (height) => ({
  chart: {
    type: 'spline',
    marker: false,
    backgroundColor: 'var(--r-light-grey)',
    height,
    spacing: [0, 20, 0, 20],
  },
  xAxis: {
    visible: false,
    type: 'datetime',
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    marker: {
      enable: false,
    },
    spline: {
      marker: {
        enable: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
})

export default {
  name: 'RTrends',
  components: { RComponentHeader, RSelect },
  props: {
    days: {
      type: Number,
      required: false,
      default: 7,
      validator: (value) => [7, 14, 30, 60].includes(value),
    },
    totalPositive: {
      type: Number,
      required: true,
    },
    totalNegative: {
      type: Number,
      required: true,
    },
    positiveEntities: {
      type: Array,
      required: true,
    },
    negativeEntities: {
      type: Array,
      required: true,
    },
    positivePercentIncrease: {
      type: Number,
      required: true,
    },
    negativePercentIncrease: {
      type: Number,
      required: true,
    },
    positiveData: {
      type: Array,
      required: true,
    },
    negativeData: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 24,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPES: {
        POSITIVE: 'POSITIVE',
        NEGATIVE: 'NEGATIVE',
      },
    }
  },
  computed: {
    items() {
      const options = [
        { label: 'Last 7 days', value: 7, selected: false },
        { label: 'Last 14 days', value: 14, selected: false },
        { label: 'Last 30 days', value: 30, selected: false },
        { label: 'Last 60 days', value: 60, selected: false },
      ]
      return options.map(
        (option) => (option.value === this.days ? { ...option, selected: true } : option),
      )
    },
    chartOptionsPositive() {
      return {
        ...getCommonConfigOfChart(this.height),
        colors: ['var(--r-positive-color)'],
        series: [{
          data: this.positiveData.map(dataToSeriesMap),
          marker: false,
        }],
      }
    },
    chartOptionsNegative() {
      return {
        ...getCommonConfigOfChart(this.height),
        colors: ['var(--r-negative-color)'],
        series: [{
          data: this.negativeData.map(dataToSeriesMap),
          marker: false,
        }],
      }
    },
  },
  methods: {
    emitInterValChange(e) {
      this.$emit('change:daterange', { value: e.value })
    },
    onClickContainerArea({ type }) {
      this.$emit('click:entity-sentiment', { value: type })
    },
    onClickEntity({ type, entity }) {
      this.$emit('click:entity', { value: { entity, entitySentiment: type } })
    },
  },
}
</script>

<style scoped>
/deep/ .rcomponentheader--end {
  width: 50%;
}
.rtrends--mt {
  margin-top: 48px;
}

.rtrends--chart-container {
  background-color: var(--r-light-grey);
  padding: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.rtrends--positive-text {
  color: var(--r-positive-color);
  font-weight: 400;
  line-height: 1.538rem;
  align-content: center;
}

.rtrends--positive-value {
  color: var(--r-positive-color);
  font-size: 1.69rem;
  font-weight: 300;
  line-height: 28px;
}

.rtrends--positive-percent {
  color: var(--r-positive-color);
  font-size: 1.69rem;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
}

.rtrends--fire-icons {
  cursor: pointer;
}

.rtrends--fire-positive {
  color: var(--r-positive-color);
  font-weight: 400;
  line-height: 1.538rem;
  margin-bottom: 0;
  cursor: pointer;
}

.rtrends--fire-negative {
  color: var(--r-negative-color);
  font-weight: 400;
  line-height: 1.538rem;
  margin-bottom: 0;
  cursor: pointer;
}

.rtrends--negative-text {
  color: var(--r-negative-color);
  line-height: 1.538rem;
  font-weight: 400;
}

.rtrends--negative-value {
  color: var(--r-negative-color);
  font-size: 1.69rem;
  font-weight: 300;
  line-height: 28px;
}

.rtrends--negative-percent {
  color: var(--r-negative-color);
  font-size: 1.69rem;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
}

.rtrends--highcharts-wrapper {
  width: 50%;
}
</style>
