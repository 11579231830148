var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtopsentimentreviewcards"
  }, [_c('r-tabs', {
    attrs: {
      "background-color": "transparent",
      "items": _vm.tabs,
      "selected-tab": _vm.selectedTab
    },
    on: {
      "change": _vm.onChangeTab
    }
  }), _c('r-tab-items', {
    key: _vm.tabKey,
    attrs: {
      "selected-tab": _vm.selectedTab
    }
  }, [_vm._t("default", function () {
    return _vm._l(_vm.tabItems, function (item) {
      return _c('r-tab-item', {
        key: item.label,
        attrs: {
          "label": item.label
        }
      }, [_vm._t("default", function () {
        return [_vm.loading ? _c('v-skeleton-loader', {
          staticClass: "mt-3",
          attrs: {
            "type": "list-item-avatar-three-line",
            "height": "144"
          }
        }) : _vm._e(), !_vm.loading && item.content._id ? _c('r-review-card', {
          attrs: {
            "search": _vm.search,
            "highlighted-entities": _vm.appliedEntities,
            "avatar-url": item.content.avatarUrl,
            "username": item.content.username,
            "date": item.content.date,
            "url": item.content.url,
            "rating": item.content.rating,
            "title": item.content.title,
            "original-title": item.content.originalTitle,
            "review-id": item.content.reviewId,
            "sentiment": item.content.sentiment,
            "language": item.content.language,
            "text": item.content.text,
            "original-text": item.content.originalText,
            "entities": item.content.entities
          }
        }) : _vm._e(), !_vm.loading && !item.content._id ? _c('r-no-results-message', {
          staticClass: "ml-1 my-5"
        }) : _vm._e()];
      })], 2);
    });
  })], 2), _c('a', {
    staticClass: "rtopsentimentreviewcards--reviews ml-5",
    on: {
      "click": _vm.onClickShowAllReviews
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reviewCard.seeAllReviews')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }