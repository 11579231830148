<template>
  <div
    ref="rwordcloud"
    class="rwordcloud"
    :class="{
      'rwordcloud--entitymapcontrols': !disableEntityMapControls,
    }"
    :style="{ height: `${height}px` }"
  >
    <span
      v-for="(word, i) in words"
      ref="word"
      :key="`word-${rendercount}-${word._id}-${word.selected}`"
      :class="{
        'rwordcloud--word-group': word.isEntityGroup,
        'rwordcloud--selected': word.selected,
      }"
      class="rwordcloud--word"
      :style="{
        fontSize: `${word.size}px`,
        top: word.position ? `${word.position.y}px` : '',
        left: word.position ? `${word.position.x}px` : '',
        visibility: word.visible ? 'visible' : 'hidden',
        color: wordColor(word),
        borderColor: borderColor(word, i),
        borderWidth: borderWidth(word),
        borderStyle: 'solid',
        'border-radius': '6px'
      }"
      :data-wordid="word._id"
      @click="onClickWord($event, word)"
    >
      <span
        class="rwordcloud--word--hovericon"
        @click="onClickWordHoverIcon(word)"
      >
        <v-icon
          small
          v-html="word.isEntityGroup ? 'edit' : 'check'"
        />
      </span>
      {{ word.label }}
    </span>
    <div
      v-if="showLoadingIndication"
      class="rwordcloud--loading"
    />
    <v-card
      v-if="hoveredWord"
      v-show="showEntityGroupPopup"
      class="rwordcloud--group-popup pa-4"
      :style="{
        left: `${popupX}px`,
        top: `${popupY}px`,
      }"
    >
      <p>
        <b>{{ hoveredWord.label }}</b> contains the entities
      </p>
      <r-chip
        v-for="entity in groupEntities.slice(0, 3)"
        :key="`entity-chip-${entity}`"
        :label="entity"
        class="mr-2 mb-2"
        style="display:inline-block"
      />
      <r-chip
        v-if="groupEntities.length > 3"
        :label="`+ ${groupEntities.length - 3} more`"
        outlined
      />
    </v-card>
    <r-no-results-message v-if="!words.length" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import debounce from '@/utils/debounce'
import {
  getColorForSentiment, getColorForRating, TIME_GROUPING_COLORS,
} from '@/utils/constants'
import RChip from '@/components/library/atoms/RChip'
import RNoResultsMessage from '@/components/library/atoms/RNoResultsMessage'
import didNotClickOn from '@/utils/didNotClickOn'
import helpers from './helpers/rwordcloud'

const COLOR_MODES = {
  DEFAULT: 'none',
  SENTIMENT: 'netSentiment',
  RATING: 'rating',
  TIME: 'time',
}

export default {
  name: 'RWordCloud',
  components: {
    RChip,
    RNoResultsMessage,
  },
  props: {
    height: {
      type: Number,
      default: 508,
    },
    minWordSize: {
      type: Number,
      default: 12,
    },
    maxWordSize: {
      type: Number,
      default: 50,
    },
    entities: {
      type: Array,
      required: true,
    },
    selectedWords: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: COLOR_MODES.DEFAULT,
      validator: (v) => Object.values(COLOR_MODES).includes(v),
    },
    disableEntityMapControls: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    appliedHighlightFilter: {
      type: String,
    },
    timeBuckets: {
      type: Array,
    },
    commonHoveredWord: {
      type: String,
    },
  },
  data: () => ({
    rendercount: 0,
    words: [],
    grid: [],
    spiralCoords: [],
    loading: false,
    debouncedOnResize: () => {},
    debouncedOnHover: () => {},
    mmTarget: null,
    popupX: 0,
    popupY: 0,
    showEntityGroupPopup: false,
    popupWordId: null,
    wordCloudComponentRef: '',
    entityItems: [],
  }),
  computed: {
    ...mapGetters('entityMaps', [
      'appliedEntityMap',
      'fetchingEditMap',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsEntities',
    ]),
    showLoadingIndication() {
      const isLoadingData = this.$props.loadingData || this.$data.loading

      return isLoadingData || this.fetchingDashboardsEntities || this.fetchingEditMap
    },
    hoveredWord() {
      const { popupWordId } = this.$data
      return this.words.find(({ _id }) => _id === popupWordId)
    },
    groupEntities() {
      if (!this.hoveredWord || !this.appliedEntityMap) {
        return []
      }
      const group = this.appliedEntityMap.groups.find(
        ({ _id }) => _id === this.hoveredWord.entityGroupId,
      )
      return group.entities
    },
  },
  watch: {
    entities(newV) {
      this.$data.entityItems = [...newV]
      this.$data.debouncedOnResize()
    },
    selectedWords(newSelectedWords) {
      this.$data.words = this.$data.words.map((datum) => ({
        ...datum,
        selected: newSelectedWords.includes(datum.label),
      }))
    },
    fetchingDashboardsEntities() {
      this.$data.words = []
    },
    loadingData(isLoading) {
      this.$data.loading = isLoading
    },
  },
  mounted() {
    this.$data.debouncedOnResize = debounce(() => this.build(), 200)
    this.$data.debouncedOnHover = debounce(this.emitHoverOnEntityEvent, 500)
    window.addEventListener('resize', () => this.$data.debouncedOnResize())
    document.addEventListener('mousemove', this.onMouseMove)
    this.wordCloudComponentRef = this.$refs.rwordcloud
    this.wordCloudComponentRef.addEventListener('mouseover', this.onMouseOver)
    this.wordCloudComponentRef.addEventListener('mouseout', this.onMouseOut)
    this.$data.entityItems = [...this.$props.entities]

    this.build()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$data.debouncedOnResize)
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseover', this.onMouseOver)
    document.removeEventListener('mouseout', this.onMouseOut)
  },
  methods: {
    build() {
      this.$data.loading = true
      this.$data.words = []
      this.$data.grid = []
      this.$data.spiralCoords = helpers.generateSpiralCoords(this.$el)
      this.generateWords()
      this.$nextTick(() => {
        this.calculateWordsBoxSizes()
        this.placeWords()
        this.$data.loading = false
      })
    },
    onMouseMove(evt) {
      const { target, clientX, clientY } = evt
      if (target.classList.contains('rwordcloud--word-group')) {
        const { left, top } = this.$el.getBoundingClientRect()
        this.$data.popupX = clientX - left
        this.$data.popupY = clientY - top
        this.$data.popupWordId = target.dataset.wordid
        this.$data.showEntityGroupPopup = true
      } else {
        this.$data.showEntityGroupPopup = false
      }
    },
    onMouseOver(evt) {
      const { target } = evt
      if (target.classList.contains('rwordcloud--word')) {
        const text = target.lastChild.wholeText.trim()
        this.$data.debouncedOnHover({ text, type: 'over' })
      }
    },
    onMouseOut(evt) {
      const { target } = evt
      if (target.classList.contains('rwordcloud--word')) {
        this.$data.debouncedOnHover({ text: '', type: 'out' })
      }
    },
    generateWords() {
      const { minWordSize, maxWordSize } = this.$props
      const { entityItems } = this.$data
      const sizes = entityItems.sort((a, b) => b.volume - a.volume).map(({ volume }) => volume)
      const minVolume = Math.min(...sizes)
      const maxVolume = Math.max(...sizes)
      const calcPayload = {
        minVolume, maxVolume, minWordSize, maxWordSize,
      }

      this.$data.words = entityItems.map((datum) => ({
        ...datum,
        size: helpers.calculateWordPixelSize({ ...calcPayload, volume: datum.volume }),
        visible: false,
        selected: this.selectedWords.includes(datum.label),
      }))
    },
    calculateWordsBoxSizes() {
      if (!this.$refs.word) {
        return
      }
      this.$refs.word.forEach((wordNode, i) => {
        const { width, height } = helpers.measureWord(wordNode)
        this.$data.words[i] = { ...this.$data.words[i], width, height }
      })
    },
    placeWords() {
      this.$data.grid = helpers.createEmptyGrid(this.$el)
      this.$data.words.forEach((word, i) => this.placeOneWord(word, i))
      this.$data.rendercount += 1
    },
    placeOneWord(word, wordIndex) {
      const { grid } = this.$data
      const { width, height } = word
      const { spiralCoords } = this
      const position = helpers.findPosition({
        spiralCoords, grid, width, height,
      })

      if (!position) {
        return
      }

      const { x, y } = position
      this.fillGridArea(x, y, width, height)
      this.$data.words[wordIndex] = { ...this.$data.words[wordIndex], position, visible: true }
    },
    fillGridArea(x, y, width, height) {
      for (let i = x; i < x + width; i += 1) {
        for (let j = y; j < y + height; j += 1) {
          this.$data.grid[i][j] = 1
        }
      }
    },
    getColor(netSentiment, rating, dateInterval) {
      if (this.$props.color === COLOR_MODES.RATING) {
        return getColorForRating(rating)
      }
      if (this.$props.color === COLOR_MODES.TIME) {
        return this.getColorForTime(dateInterval)
      }
      return getColorForSentiment(netSentiment)
    },
    wordColor({ netSentiment, rating, dateInterval }) {
      return this.getColor(netSentiment, rating, dateInterval)
    },
    borderColor(word) {
      const {
        netSentiment, isEntityGroup, rating, dateInterval, maxDate,
      } = word
      if (this.wordIsCommonOrUnique(word) || (isEntityGroup && this.appliedEntityMap)) {
        return this.getColor(netSentiment, rating, dateInterval, maxDate)
      }
      return 'white'
    },
    onClickWordHoverIcon(word) {
      const { isEntityGroup, entityGroupId, selected } = word
      if (isEntityGroup) {
        this.$emit('click:edit-entity-map-group', { value: entityGroupId })
        return
      }
      this.$data.words.find((w) => w._id === word._id).selected = !selected
      this.$forceUpdate()
      const value = this.$data.words.filter((w) => w.selected)
      this.$emit('change:selected-words', { value })
    },
    onClickWord(evt, value) {
      if (didNotClickOn(evt.target, { classes: ['rwordcloud--word--hovericon'] })) {
        this.$emit('click:word', { value })
      }
    },
    getColorForTime(date) {
      if (date) {
        const regex = /\d{4}-\d{2}-\d{2}/g
        const [_, dateTo] = date.match(regex)
        // eslint-disable-next-line max-len
        const index = this.timeBuckets.findIndex((item) => this.$moment(dateTo).isSameOrBefore(item)) - 1
        return TIME_GROUPING_COLORS[index]
      }
      return TIME_GROUPING_COLORS[Math.ceil(Math.random(0, (TIME_GROUPING_COLORS.length - 1)))]
    },
    borderWidth(word) {
      if (word.isEntityGroup && this.appliedEntityMap) {
        if (this.wordIsCommonOrUnique(word)) {
          return '3px'
        }
        return '1px'
      }

      if (this.wordIsCommonOrUnique(word)) {
        return '1px'
      }

      return '0px'
    },
    wordIsCommonOrUnique(word) {
      return ((this.appliedHighlightFilter === 'uniqueWords' && word.isUnique)
      || (this.appliedHighlightFilter === 'commonWords' && word.isCommon) || (word.label === this.$props.commonHoveredWord))
    },
    emitHoverOnEntityEvent({ text, type }) {
      if (type === 'over') {
        this.$emit('on:hover-over-entity', { text })
      } else {
        this.$emit('on:hover-out-entity')
      }
    },
  },
}
</script>

<style scoped>
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.95);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.rwordcloud {
  position: relative;
}
.rwordcloud--word {
  position: absolute;
  padding: 3px 6px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  animation: scale-up-center 0.8s linear forwards;
}

.rwordcloud--loading {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--r-light-grey);
}
.rwordcloud--word:hover,
.rwordcloud--word-group,
.rwordcloud--selected {
  padding: 2px 5px;
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  cursor: pointer;
}
.rwordcloud--word:hover{
  font-weight: 700;
}
.rwordcloud--group-popup {
  position: absolute;
  z-index: 2;
  width: 290px;
  height: 150px;
  margin-left: -140px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 1px 1px 5px -2px;
}
.rwordcloud--word--hovericon {
  position: absolute;
  z-index: 1;
  display: none;
  border: 1px solid;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  right: -0.7rem;
  top: -0.7rem;
  background: #fff;
}
.rwordcloud--entitymapcontrols .rwordcloud--word:hover .rwordcloud--word--hovericon,
.rwordcloud--selected .rwordcloud--word--hovericon {
  display: block;
}
/deep/ .rwordcloud--word--hovericon .v-icon {
  position: absolute;
  color: inherit;
  font-size: 1rem !important;
  margin: 0.1rem 0.13rem;
  text-shadow: none;
}
.rwordcloud--selected {
  background: var(--r-grey);
}
.rwordcloud--selected .rwordcloud--word--hovericon {
  background: var(--primary-color);
  color: white;
}
.rwordcloud--word-group .rwordcloud--word--hovericon {
  background: var(--primary-color);
  color: white;
}

</style>
