// here we can have sample data not to mess Component library component
export const PIECHART_DATA = [
  { name: 'Amazon.co.uk', value: 40 },
  { name: 'shopee.com', value: 5 },
  { name: 'wish.com', value: 15 },
  { name: 'daraz.pk', value: 5 },
  { name: 'shopify.com', value: 35 },
]

export const RSENTIMENT_BAR_CHART_SAMPLE_DATA = [
  { label: 'United Kingdom', value: { positive: 400, neutral: 350, negative: 220 } },
  { label: 'Poland', value: { positive: 480, neutral: 500, negative: 250 } },
  { label: 'Armenia', value: { positive: 100, neutral: 340, negative: 150 } },
  { label: 'Country 1', value: { positive: 100, neutral: 340, negative: 150 } },
  { label: 'The United Kingdom of Great Britain and Northern Ireland', value: { positive: 100, neutral: 340, negative: 150 } },
  { label: 'Country 3', value: { positive: 110, neutral: 360, negative: 500 } },
  { label: 'Country 4', value: { positive: 300, neutral: 340, negative: 400 } },
]

export const SITE_ANALYZED_TABLE_DATA = [
  {
    hostname: 'amazon.co.uk',
    numReviews: 230,
    netSentiment: 0.6,
    averageRating: 5.0,
  },
  {
    hostname: 'shopee.com',
    numReviews: 4434,
    netSentiment: 0.6,
    averageRating: 4.5,
  },
  {
    hostname: 'wish.com',
    numReviews: 14454,
    netSentiment: 0.6,
    averageRating: 4.3,
  },
  {
    hostname: 'daraz.pk',
    numReviews: 4354,
    netSentiment: 0.6,
    averageRating: 5.0,
  },
  {
    hostname: 'shopify.com',
    numReviews: 8984,
    netSentiment: 0.6,
    averageRating: 4.8,
  },
]

export const COMPARISON_CHART_DATA = {
  data: [
    [
      { value: 60, date: '2021-01-01' },
      { value: 50, date: '2021-01-15' },
      { value: 82, date: '2021-02-01' },
      { value: 70, date: '2021-02-15' },
      { value: 60, date: '2021-03-01' },
      { value: 83, date: '2021-03-15' },
      { value: 100, date: '2021-04-01' },
      { value: 117, date: '2021-04-15' },
      { value: 98, date: '2021-05-01' },
      { value: 72, date: '2021-05-15' },
      { value: 117, date: '2021-06-01' },
      { value: 84, date: '2021-06-15' },
      { value: 200, date: '2021-07-01' },
      { value: 100, date: '2021-07-15' },
      { value: 90, date: '2021-08-01' },
      { value: 45, date: '2021-08-15' },
      { value: 129, date: '2021-09-01' },
      { value: 217, date: '2021-09-15' },
      { value: 98, date: '2021-10-01' },
      { value: 44, date: '2021-10-15' },
      { value: 150, date: '2021-11-01' },
      { value: 420, date: '2021-11-15' },
      { value: 56, date: '2021-12-01' },
      { value: 300, date: '2021-12-15' },
      { value: 110, date: '2022-01-01' },
      { value: 57, date: '2022-01-15' },
      { value: 300, date: '2022-02-01' },
      { value: 210, date: '2022-02-15' },
      { value: 299, date: '2022-03-01' },
      { value: 35, date: '2022-03-15' },
      { value: 333, date: '2022-04-01' },
      { value: 210, date: '2022-04-15' },
      { value: 300, date: '2022-05-01' },
      { value: 110, date: '2022-05-15' },
      { value: 300, date: '2022-06-01' },
      { value: 45, date: '2022-06-15' },
      { value: 210, date: '2022-07-01' },
      { value: 230, date: '2022-07-15' },
      { value: 150, date: '2022-08-01' },
      { value: 190, date: '2022-08-15' },
      { value: 210, date: '2022-09-01' },
      { value: 150, date: '2022-09-15' },
      { value: 234, date: '2022-10-01' },
      { value: 150, date: '2022-10-15' },
      { value: 300, date: '2022-11-01' },
      { value: 300, date: '2022-11-15' },
      { value: 299, date: '2022-12-01' },
      { value: 190, date: '2022-12-15' },
    ],
    [
      { value: [100, 23, 45], date: '2021-01-01' },
      { value: [100, 23, 45], date: '2021-01-15' },
      { value: [100, 23, 45], date: '2021-02-01' },
      { value: [100, 23, 45], date: '2021-02-15' },
      { value: [100, 23, 45], date: '2021-03-01' },
      { value: [100, 23, 45], date: '2021-03-15' },
      { value: [100, 23, 45], date: '2021-04-01' },
      { value: [100, 23, 45], date: '2021-04-15' },
      { value: [100, 23, 45], date: '2021-05-01' },
      { value: [100, 23, 45], date: '2021-05-15' },
      { value: [100, 23, 45], date: '2021-06-01' },
      { value: [100, 23, 45], date: '2021-06-15' },
      { value: [100, 23, 45], date: '2021-07-01' },
      { value: [100, 23, 45], date: '2021-07-15' },
      { value: [100, 23, 45], date: '2021-08-01' },
      { value: [100, 23, 45], date: '2021-08-15' },
      { value: [100, 23, 45], date: '2021-09-01' },
      { value: [100, 23, 45], date: '2021-09-15' },
      { value: [100, 23, 45], date: '2021-10-01' },
      { value: [100, 23, 45], date: '2021-10-15' },
      { value: [100, 23, 45], date: '2021-11-01' },
      { value: [100, 23, 45], date: '2021-11-15' },
      { value: [100, 23, 45], date: '2021-12-01' },
      { value: [100, 23, 45], date: '2021-12-15' },
      { value: [100, 23, 45], date: '2022-01-01' },
      { value: [100, 23, 45], date: '2022-01-15' },
      { value: [100, 23, 45], date: '2022-02-01' },
      { value: [100, 23, 45], date: '2022-02-15' },
      { value: [100, 23, 45], date: '2022-03-01' },
      { value: [100, 23, 45], date: '2022-03-15' },
      { value: [100, 23, 45], date: '2022-04-01' },
      { value: [100, 23, 45], date: '2022-04-15' },
      { value: [100, 23, 45], date: '2022-05-01' },
      { value: [100, 23, 45], date: '2022-05-15' },
      { value: [100, 23, 45], date: '2022-06-01' },
      { value: [100, 23, 45], date: '2022-06-15' },
      { value: [100, 23, 45], date: '2022-07-01' },
      { value: [100, 23, 45], date: '2022-07-15' },
      { value: [100, 23, 45], date: '2022-08-01' },
      { value: [100, 23, 45], date: '2022-08-15' },
      { value: [100, 23, 45], date: '2022-09-01' },
      { value: [100, 23, 45], date: '2022-09-15' },
      { value: [100, 23, 45], date: '2022-10-01' },
      { value: [100, 23, 45], date: '2022-10-15' },
      { value: [100, 23, 45], date: '2022-11-01' },
      { value: [100, 23, 45], date: '2022-11-15' },
      { value: [100, 23, 45], date: '2022-12-01' },
      { value: [100, 23, 45], date: '2022-12-15' },
    ],
  ],
  breakdownKeys: [
    {
      label: 'Positive',
    },
    {
      label: 'Neutral',
    },
    {
      label: 'Negative',
    },
  ],
}

export const MAP_DATA = [
  {
    label: 'AFG',
    value: 10,
  },
  {
    label: 'NLD',
    value: 230,
  },
  {
    label: 'FRA',
    value: 456,
  },
  {
    label: 'PAK',
    value: 400,
  },
  {
    label: 'USA',
    value: 1450,
  },
  {
    label: 'SGP',
    value: 1000,
  },
  {
    label: 'CHN',
    value: 3098,
  },
  {
    label: 'BRA',
    value: 789,
  },
  {
    label: 'DEU',
    value: 1234,
  },
  {
    label: 'TUR',
    value: 456,
  },
  {
    label: 'GRL',
    value: 32,
  },
  {
    label: 'ISL',
    value: 3,
  },
  {
    label: 'MYS',
    value: 231,
  },
]

export const RTRENDINGENTITIES_POSITIVE_SAMPLE_DATA = [
  { date: '2022-01-01', value: 30 },
  { date: '2022-01-02', value: 31 },
  { date: '2022-01-03', value: 32 },
  { date: '2022-01-04', value: 25 },
  { date: '2022-01-05', value: 0 },
  { date: '2022-01-06', value: 12 },
  { date: '2022-01-07', value: 15 },
  { date: '2022-01-08', value: 50 },
]
export const RTRENDINGENTITIES_NEGATIVE_SAMPLE_DATA = [
  { date: '2022-01-01', value: 3 },
  { date: '2022-01-02', value: 10 },
  { date: '2022-01-03', value: 15 },
  { date: '2022-01-05', value: 38 },
  { date: '2022-02-05', value: 48 },
]

export const REVIEWCARD_DATA = {
  search: 'arrived',
  avatarUrl: 'https://via.placeholder.com/150',
  username: 'Olivia Johnson',
  date: '2022-07-13T14:25:24.296+00:00',
  url: 'https://via.placeholder.com/150',
  rating: 5,
  title: 'NotOriginal - Product is great!',
  originalTitle: 'Product is great!',
  reviewId: 'reviewId',
  sentiment: 0.9,
  language: 'es',
  text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
  originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
  entities: [{
    extractedField: 'text',
    name: 'arrived',
    entitySentiment: 1,
    entityTextOffset: 22,
  }, {
    extractedField: 'text',
    name: 'complete',
    entitySentiment: 1,
    entityTextOffset: 41,
  },
  {
    extractedField: 'text',
    name: 'undamaged',
    entitySentiment: 1,
    entityTextOffset: 30,
  }],
}

export const RRATING_TIME_LINE_CHART_SAMPLE_DATA = [
  { value: 3.0, date: '2022-02-06' },
  { value: 3.8, date: '2022-03-10' },
  { value: 3.9, date: '2022-04-12' },
  { value: 4.1, date: '2022-05-06' },
  { value: 4.6, date: '2022-06-08' },
  { value: 4.0, date: '2022-07-01' },
]

export const REVIEWCARD_DATA_NEGATIVE = {
  search: 'arrived',
  avatarUrl: 'https://via.placeholder.com/150',
  username: 'Johnson 1',
  date: '2022-08-14T14:25:24.296+00:00',
  url: 'https://via.placeholder.com/150',
  rating: 1,
  title: 'Product is  terrible!',
  reviewId: 'reviewId',
  sentiment: 0.2,
  language: 'es',
  text: 'NotOriginal - The set arrived damaged',
  originalText: 'Original - The set arrived damaged',
  entities: [{
    name: 'arrived',
    entitySentiment: 1,
    entityTextOffset: 22,
  }, {
    name: 'complete',
    entitySentiment: 1,
    entityTextOffset: 41,
  },
  {
    name: 'undamaged',
    entitySentiment: 1,
    entityTextOffset: 30,
  }],
}

export const REVIEWCARDLIST_DATA = {
  page: 1,
  totalPages: 10,
  pageSize: 10,
  totalReviews: 1000,
  reviews: [
    {
      search: 'arrived',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      title: 'NotOriginal - Product is great!',
      originalTitle: 'Product is great!',
      reviewId: 'reviewId1',
      sentiment: 0.9,
      language: 'es',
      text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
      originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 22,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 41,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 30,
      }],
    }, {
      search: 'original',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      originalTitle: 'Product is great!',
      reviewId: 'reviewId2',
      sentiment: 0.9,
      language: 'en',
      originalText: 'The set arrived undamaged, complete, original and arrived well sealed.',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 19,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 38,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 27,
      }],
    }, {
      search: 'arrived',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      title: 'NotOriginal - Product is great!',
      originalTitle: 'Product is great!',
      reviewId: 'reviewId3',
      sentiment: 0.9,
      language: 'es',
      text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
      originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 22,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 41,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 30,
      }],
    }, {
      search: 'arrived',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      title: 'NotOriginal - Product is great!',
      originalTitle: 'Product is great!',
      reviewId: 'reviewId4',
      sentiment: 0.9,
      language: 'es',
      text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
      originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 22,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 41,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 30,
      }],
    }, {
      search: 'arrived',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      title: 'NotOriginal - Product is great!',
      originalTitle: 'Product is great!',
      reviewId: 'reviewId5',
      sentiment: 0.9,
      language: 'es',
      text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
      originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 22,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 41,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 30,
      }],
    }, {
      search: 'arrived',
      avatarUrl: 'https://via.placeholder.com/150',
      username: 'Olivia Johnson',
      date: '2022-07-13T14:25:24.296+00:00',
      url: 'https://via.placeholder.com/150',
      rating: 5,
      title: 'NotOriginal - Product is great!',
      originalTitle: 'Product is great!',
      reviewId: 'reviewId6',
      sentiment: 0.9,
      language: 'es',
      text: 'NotOriginal - The set arrived undamaged, complete, original and arrived well sealed. The set arrived undamaged, complete, original and arrived well sealed',
      originalText: 'Original - The set arrived undamaged, complete, original and well sealed',
      entities: [{
        name: 'arrived',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 22,
      }, {
        name: 'complete',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 41,
      },
      {
        name: 'undamaged',
        extractedField: 'text',
        entitySentiment: 1,
        entityTextOffset: 30,
      }],
    },
  ],
}

export const TOPIC_WHEEL_DATA = [
  {
    label: 'Product 1',
    value: 33,
    data: [
      {
        label: 'Topic 1',
        value: 34,
        data: [
          {
            label: 'Word 1',
            value: 8,
          },
          {
            label: 'Word 2',
            value: 7,
          },
          {
            label: 'Word 3',
            value: 17,
          },
          {
            label: 'Word 4',
            value: 12,
          },
        ],
      },
      {
        label: 'Topic 2',
        value: 34,
        data: [
          {
            label: 'Word 1',
            value: 8,
          },
          {
            label: 'Word 2',
            value: 7,
          },
          {
            label: 'Word 3',
            value: 4,
          },
          {
            label: 'Word 4',
            value: 8,
          },
          {
            label: 'Word 5',
            value: 12,
          },
        ],
      },
      {
        label: 'Topic 3',
        value: 34,
        data: [
          {
            label: 'Word 1',
            value: 8,
          },
          {
            label: 'Word 2',
            value: 7,
          },
          {
            label: 'Word 3',
            value: 4,
          },
          {
            label: 'Word 4',
            value: 8,
          },
        ],
      },
      {
        label: 'Topic 4',
        value: 34,
        data: [
          {
            label: 'Word 1',
            value: 8,
          },
          {
            label: 'Word 2',
            value: 7,
          },
          {
            label: 'Word 3',
            value: 4,
          },
        ],
      },
    ],
  },
  {
    label: 'Product 2',
    value: 33,
    data: [
      {
        label: 'Topic 1',
        value: 78,
        data: [
          {
            label: 'Word 1',
            value: 34,
          },
          {
            label: 'Word 2',
            value: 34,
          },
          {
            label: 'Word 3',
            value: 34,
          },
        ],
      },
      {
        label: 'Topic 2',
        value: 90,
        data: [
          {
            label: 'Word 1',
            value: 190,
          },
        ],
      },
      {
        label: 'Topic 3',
        value: 32,
        data: [
          {
            label: 'Word 1',
            value: 33,
          },
          {
            label: 'Word 2',
            value: 67,
          },
        ],
      },
    ],
  },
  {
    label: 'Product 3',
    value: 34,
    data: [
      {
        label: 'Topic 1',
        value: 34,
        data: [
          {
            label: 'Word 1',
            value: 133,
          },
          {
            label: 'Word 2',
            value: 33,
          },
          {
            label: 'Word 3',
            value: 34,
          },
        ],
      },
    ],
  },
]

const availableWords = [
  'games',
  'game',
  'collection',
  'mario',
  'nintendo',
  'switch',
  'sunshine',
  'galaxy',
  'controls',
  'mario 64',
  'price',
  'fun',
  'people',
  'one',
  'graphics',
  'version',
  'way',
  'wii',
  'release',
  'gamecube',
  'super mario 64',
  'camera',
  'nostalgia',
  'super mario sunshine',
  'product',
  'package',
  'mario galaxy',
  'fan',
  'amazon',
  'classics',
  'all',
  'titles',
  'some',
  'condition',
  'stars',
  'mario sunshine',
  'childhood',
  'ports',
  'lot',
  'super mario galaxy',
  'controller',
  'kids',
  'soundtracks',
  'copy',
  'effort',
  'things',
  'n64',
  'port',
  'son',
  'cartridge',
]

export const WORD_CLOUD_DATA = availableWords.map((w) => ({
  _id: `${Math.random() * 30000000}`,
  label: w,
  volume: Math.floor(Math.random() * 10000),
  netSentiment: (Math.random() * 2) - 1,
  rating: Math.random() * 5,
}))

export const TOP_URL_DATA = {
  highestRated: [
    {
      _id: '1',
      name: 'Iphone: is a mobile phone. It is produced by Apple which is an american company. They introduce a new model of iPhone almost every year.',
      averageRating: 4.5,
      numReviews: 2456,
    }, {
      _id: '2',
      name: 'Samsung',
      averageRating: 4.9,
      numReviews: 7654,
    }, {
      _id: '3',
      name: 'Motorola',
      averageRating: 5,
      numReviews: 2345,
    },
  ],
  mostReviewed: [{
    _id: '1',
    name: 'Tesla, is a car company that produces electric vehicles. It has disrupted car industry and is a major player in electric vehicles industry.',
    averageRating: 3.5,
    numReviews: 7665,
  }, {
    _id: '1',
    name: 'Apple',
    averageRating: 2.5,
    numReviews: 23,
  }, {
    _id: '1',
    name: 'Toyota',
    averageRating: 4.5,
    numReviews: 3455,
  }],
}

export const RRATING_TIME_LINE_COMPARISON_SAMPLE_DATA = [
  {
    _id: '62dfb0312894082017215f13',
    name: 'iPhone 12',
    history: [
      { dateFrom: '2022-10-01', dateTo: '2022-10-01', averageRating: 2.1 },
      { dateFrom: '2022-10-02', dateTo: '2022-10-02', averageRating: 2.2 },
      { dateFrom: '2022-10-03', dateTo: '2022-10-03', averageRating: 2.3 },
      { dateFrom: '2022-10-04', dateTo: '2022-10-04', averageRating: 2.8 },
      { dateFrom: '2022-10-05', dateTo: '2022-10-05', averageRating: 2.9 },
      { dateFrom: '2022-10-06', dateTo: '2022-10-06', averageRating: 3.0 },
      { dateFrom: '2022-10-07', dateTo: '2022-10-07', averageRating: 3.0 },
    ],
  },
  {
    _id: '62e3ea56101460697de8ca0c',
    name: 'Google Pixel 6',
    history: [
      { dateFrom: '2022-10-01', dateTo: '2022-10-01', averageRating: 3.1 },
      { dateFrom: '2022-10-02', dateTo: '2022-10-02', averageRating: 2.5 },
      { dateFrom: '2022-10-03', dateTo: '2022-10-03', averageRating: 3.2 },
      { dateFrom: '2022-10-04', dateTo: '2022-10-04', averageRating: 1.1 },
      { dateFrom: '2022-10-05', dateTo: '2022-10-05', averageRating: 1.3 },
      { dateFrom: '2022-10-06', dateTo: '2022-10-06', averageRating: 1.3 },
      { dateFrom: '2022-10-07', dateTo: '2022-10-07', averageRating: 1.3 },
    ],
  },
  {
    _id: '62e80e24956678e4f5217320',
    name: 'Samsung 22',
    history: [
      { dateFrom: '2022-10-01', dateTo: '2022-10-01', averageRating: 4.1 },
      { dateFrom: '2022-10-02', dateTo: '2022-10-02', averageRating: 3.1 },
      { dateFrom: '2022-10-03', dateTo: '2022-10-03', averageRating: 4.3 },
      { dateFrom: '2022-10-04', dateTo: '2022-10-04', averageRating: 1.1 },
      { dateFrom: '2022-10-05', dateTo: '2022-10-05', averageRating: 1.5 },
      { dateFrom: '2022-10-06', dateTo: '2022-10-06', averageRating: 1.8 },
      { dateFrom: '2022-10-07', dateTo: '2022-10-07', averageRating: 1.5 },
    ],
  },
  {
    _id: '628df911344ee0d11cceeb2d',
    name: 'Samsung s21',
    history: [
      { dateFrom: '2022-10-01', dateTo: '2022-10-01', averageRating: 2.3 },
      { dateFrom: '2022-10-02', dateTo: '2022-10-02', averageRating: 2.4 },
      { dateFrom: '2022-10-03', dateTo: '2022-10-03', averageRating: 3.1 },
      { dateFrom: '2022-10-04', dateTo: '2022-10-04', averageRating: 4.1123 },
      { dateFrom: '2022-10-05', dateTo: '2022-10-05', averageRating: 1.823 },
      { dateFrom: '2022-10-06', dateTo: '2022-10-06', averageRating: 1.87 },
      { dateFrom: '2022-10-07', dateTo: '2022-10-07', averageRating: 1.88 },
    ],
  },
  {
    _id: '631b0b2b284260f7866ac89f',
    name: 'Huawei P40 pro',
    history: [
      { dateFrom: '2022-10-01', dateTo: '2022-10-01', averageRating: 1.1 },
      { dateFrom: '2022-10-02', dateTo: '2022-10-02', averageRating: 2.1 },
      { dateFrom: '2022-10-03', dateTo: '2022-10-03', averageRating: 3.01 },
      { dateFrom: '2022-10-04', dateTo: '2022-10-04', averageRating: 4.448 },
      { dateFrom: '2022-10-05', dateTo: '2022-10-05', averageRating: 3.333 },
      { dateFrom: '2022-10-06', dateTo: '2022-10-06', averageRating: 4.3 },
      { dateFrom: '2022-10-07', dateTo: '2022-10-07', averageRating: 1.3 },
    ],
  },
]

export const RTEXT_CHIPS_SAMPLE_DATA = ['text', 'chips', 'here']

export const RSTACKED_BAR_CHART_SAMPLE_DATA = {
  properties: ['positive', 'neutral', 'negative'],
  data: {
    name: 'iPhone 12',
    total: 100,
    positive: 17,
    neutral: 39,
    negative: 44,
  },
}
