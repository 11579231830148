import { KEY_TO_COLOR_MAP } from '@/utils/constants'

const chartKeyToColor = (key) => {
  if (!key) {
    return null
  }

  return KEY_TO_COLOR_MAP[key.toLowerCase()]
}

export default chartKeyToColor
